import ServiceRequest from '../../domain/ServiceRequest/ServiceRequest';
import HttpClient from '@src/core/infrastructure/http';
import { ServiceRequestCreated } from '@src/ui/apps/ServiceRequest/ServiceRequestEvents';
import { eventBusSingleton } from '@src/core/infrastructure/Events/EventBus';

type CreateServiceRequestResponseJSON = {
  serviceRequestUrl: string;
  serviceRequestId: string;
};

export type CreateServiceRequestSuccess = {
  url: string;
  id: string;
};

class CreateServiceRequest {
  constructor(private serverUrl: string, private httpClient: HttpClient) {}

  async execute(serviceRequest: ServiceRequest): Promise<CreateServiceRequestSuccess> {
    const data = CreateServiceRequest.toDto(serviceRequest);
    const response = await this.httpClient.post<CreateServiceRequestResponseJSON>(
      this.serverUrl + '/serviceRequest',
      data
    );

    const returnValue = {
      url: response.data().serviceRequestUrl,
      id: response.data().serviceRequestId,
    };
    eventBusSingleton.fireEvent(new ServiceRequestCreated(serviceRequest, returnValue));

    return returnValue;
  }

  private static toDto(serviceRequest: ServiceRequest): Record<string, unknown> {
    const data: Record<string, unknown> = {
      jobTypeId: serviceRequest.jobTypeData.id,
      answers: serviceRequest.answers,
      description: serviceRequest.description,
      photoIds: serviceRequest.photoIds,
      customerContactData: {
        customerName: serviceRequest.customerContactData.name,
        customerEmail: serviceRequest.customerContactData.email,
        customerPhone: serviceRequest.customerContactData.phone,
      },
      termsAndConditions: serviceRequest.termsAndConditions,
      thirdParty: serviceRequest.thirdParty,
      postalCode: serviceRequest.locationIdentifier.postalCode,
      locationId: serviceRequest.locationIdentifier.locationId,
    };

    if (serviceRequest.visibility) {
      data.visibility = {
        tradeId: serviceRequest.visibility.businessId,
        visibleToAllTrades: serviceRequest.visibility.visibleToOtherBusinesses,
      };
    }

    if (serviceRequest.additionalData) {
      data.additionalData = {
        medium: serviceRequest.additionalData.sourceMedium,
        sourceStartPage: serviceRequest.additionalData.sourceStartPage,
        sourceEndPage: serviceRequest.additionalData.sourceEndPage,
      };
    }

    if (serviceRequest.clientId) {
      data.clientId = serviceRequest.clientId;
    }

    return CreateServiceRequest.removeEmptyFields(data);
  }

  private static removeEmptyFields(data: Record<string, unknown>): Record<string, unknown> {
    return JSON.parse(JSON.stringify(data));
  }
}

export default CreateServiceRequest;
