import React from 'react';
import Link from '@src/ui/components/Link/Link';
import I18n from '@src/core/I18n';

function PrivacyPolicyLink(props: { linkText: string }): React.ReactElement {
  const LOCALE = I18n.intl().locale;

  switch (LOCALE) {
    case 'es-ES':
      return (
        <Link href="https://www.habitissimo.es/politica_de_privacidad" target="_blank">
          {props.linkText}
        </Link>
      );
    case 'it-IT':
      return (
        <Link href="https://www.habitissimo.it/politica_sulla_privacy" target="_blank">
          {props.linkText}
        </Link>
      );
    case 'pt-PT':
      return (
        <Link href="https://www.habitissimo.pt/politica_de_privacidade" target="_blank">
          {props.linkText}
        </Link>
      );
    case 'pt-BR':
      return (
        <Link href="https://www.habitissimo.com.br/politica_de_privacidade" target="_blank">
          {props.linkText}
        </Link>
      );
    case 'es-MX':
      return (
        <Link href="https://www.habitissimo.com.mx/politica_de_privacidad" target="_blank">
          {props.linkText}
        </Link>
      );
    case 'es-CL':
      return (
        <Link href="https://www.habitissimo.cl/politica_de_privacidad" target="_blank">
          {props.linkText}
        </Link>
      );
    default:
      throw new Error('Country has no privacy policy url');
  }
}

export default PrivacyPolicyLink;
