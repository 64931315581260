import Category from '@src/core/domain/Categories/Category';
import CategoryNotFoundError from '@src/core/domain/Categories/CategoryNotFoundError';

class CategoryGroupTree {
  private _categories: Category[];

  constructor(categories: Category[]) {
    this._categories = categories;
  }

  toArray(): Category[] {
    return this._categories;
  }

  length(): number {
    return this._categories.length;
  }

  findCategoryById(categoryId: string): Category {
    return this.categoryFinder((category) => category.id === categoryId);
  }

  findCategoryByName = (categoryName: string): Category => {
    return this.categoryFinder((category) => category.name === categoryName);
  };

  findCategoryByJobTypeId = (jobTypeId: string): Category => {
    const flattenCategories = this._categories.map((category) => category.children).flat();
    const category = flattenCategories.find((category) => {
      return category.children.find((jobType) => jobType.id === jobTypeId);
    });

    return this.checkCategoryFound(category);
  };

  private categoryFinder(finder: (category: Category) => boolean): Category {
    const categoryGroup = this._categories.find(finder);

    if (categoryGroup) {
      return categoryGroup;
    }

    const categories = this._categories.flatMap((category) => category.children);
    const category = categories.find(finder);

    if (category) {
      return category;
    }

    const jobTypes = categories.flatMap((category) => category.children);
    const jobType = jobTypes.find(finder);

    return this.checkCategoryFound(jobType);
  }

  private checkCategoryFound(category: Category | undefined): Category | never {
    if (!category) {
      throw new CategoryNotFoundError('This Error should not have occurred');
    }
    return category;
  }
}

export default CategoryGroupTree;
