import React, { FunctionComponent } from 'react';
import styles from './Alert.module.scss';
import cx from 'classnames';
import Icon from '@src/ui/components/Icon/Icon';

export enum AlertTypes {
  SUCCESS = 'success',
  INFORMATION = 'info',
  BANNER = 'banner',
  ERROR = 'error',
  WARNING = 'warning',
}

export enum AlertSize {
  SMALL = 'size-small',
  MEDIUM = 'size-medium',
}

type AlertProps = {
  variant: AlertTypes;
  size?: AlertSize;
  icon?: React.ReactElement | null;
  children?: React.ReactNode;
};

const defaultIcons: Record<AlertTypes, React.ReactElement> = {
  [AlertTypes.INFORMATION]: <Icon name="info-circle-o" />,
  [AlertTypes.BANNER]: <Icon name="info-circle-o" />,
  [AlertTypes.ERROR]: <Icon name="ban-o" />,
  [AlertTypes.SUCCESS]: <Icon name="check-o" />,
  [AlertTypes.WARNING]: <Icon name="warning-o" />,
};

const Alert: FunctionComponent<AlertProps> = (props: AlertProps) => {
  const variantClass = `alert--${props.variant}`;
  const className = cx(styles.alert, styles[variantClass], [
    { [styles[`alert--${props.size}`]]: props.size },
  ]);
  const icon = props.icon === null ? null : props.icon || defaultIcons[props.variant];

  return (
    <div role="alert" className={className}>
      {icon && <div className={styles['alert__icon-wrapper']}>{icon}</div>}
      <div className={styles.alert__content}>{props.children}</div>
    </div>
  );
};

export default Alert;
