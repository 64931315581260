import React, { useEffect } from 'react';

import Button, { ButtonTypes } from '@src/ui/components/Button/Button';
import StepTitle from '@src/ui/apps/ServiceRequest/Steps/StepTitle';
import { FormattedMessage } from 'react-intl';
import styles from '../styles.module.scss';
import { eventBusSingleton } from '@src/core/infrastructure/Events/EventBus';
import { ViewServiceRequestAboutToQuit } from '../../ServiceRequestEvents';

type InitStepProps = {
  nextStep: () => void;
  goBackStepsToCloseForm: () => void;
};

export function InitStep({ nextStep, goBackStepsToCloseForm }: InitStepProps): React.ReactElement {
  useEffect(() => {
    eventBusSingleton.fireEvent(new ViewServiceRequestAboutToQuit());
  }, []);

  return (
    <section data-testid="InitStep" className={styles['container']}>
      <div>
        <StepTitle className="step-title" data-testid="description-title">
          <FormattedMessage
            id="serviceRequestForm.CloseForm.title"
            defaultMessage="Não perca a oportunidade de realizar os seus planos!"
            description="Não perca a oportunidade de realizar os seus planos!"
          />
        </StepTitle>

        <p className={styles['center-step']}>
          Você está a poucos passos de conseguir até 4 orçamentos personalizados para o serviço que
          você precisa.
        </p>
        <p className={styles['center-step']}>
          Que tal voltar e responder às perguntas para ficar mais perto de transformar os seus
          planos em realidade?
        </p>
      </div>
      <div className={styles['button-group']}>
        <Button
          type="button"
          variant={ButtonTypes.SECONDARY}
          onClick={nextStep}
          data-testid="cancelTask"
        >
          Cancelar pedido
        </Button>
        <Button
          type="button"
          variant={ButtonTypes.PRIMARY}
          onClick={goBackStepsToCloseForm}
          data-testid="back"
        >
          Voltar
        </Button>
      </div>
    </section>
  );
}
