import {
  Action,
  ServiceRequestFormState,
  serviceRequestFormStateReducer,
} from '@src/ui/apps/ServiceRequest/serviceRequestFormReducer';
import {
  QuestionsLoaded,
  ServiceRequestFormSubmitted,
} from '@src/ui/apps/ServiceRequest/ServiceRequestEvents';
import { useEffect, useReducer } from 'react';
import usePhotoUpload, {
  usePhotoUploadMethods,
} from '@src/ui/apps/ServiceRequest/hooks/usePhotoUpload';

import CategoryNotFoundError from '@src/core/domain/Categories/CategoryNotFoundError';
import { CategoryType } from '@src/core/domain/Categories/Category';
import CreateServiceRequestFactory from '@src/core/useCases/ServiceRequest/CreateServiceRequestFactory';
import CustomerContactData from '@src/core/domain/CustomerContactData/CustomerContactData';
import { CustomerContactStepData } from '@src/ui/apps/ServiceRequest/Steps/CustomerContactStep/CustomerContactStep';
import { DescriptionStepData } from './Steps/DescriptionStep/DescriptionStep';
import FetchAllCategoriesFactory from '@src/core/useCases/Category/FetchAllCategoriesFactory';
import FetchCategoryBySlugFactory from '@src/core/useCases/Category/FetchCategoryBySlugFactory';
import FetchServiceRequestQuestionsFactory from '@src/core/useCases/Questions/FetchServiceRequestQuestionsFactory';
import { FormConfiguration } from '@src/core/ApplicationConfiguration';
import HttpError from '@src/core/infrastructure/http/HttpError';
import { JobTypeStepData } from '@src/ui/apps/ServiceRequest/Steps/JobTypeStep/JobTypeStep';
import LocationIdentifier from '@src/core/domain/Locations/LocationIdentifier';
import { LocationStepData } from '@src/ui/apps/ServiceRequest/Steps/LocationStep/LocationStep';
import Question from '@src/core/domain/Questions/Question';
import { QuestionStepsData } from './Steps/QuestionStep/QuestionStep';
import ServiceRequest from '@src/core/domain/ServiceRequest/ServiceRequest';
import UploadedPhoto from '@src/core/domain/Photos/UploadedPhoto';
import { eventBusSingleton } from '@src/core/infrastructure/Events/EventBus';
import { mapError } from '@src/ui/apps/ServiceRequest/errors/mapError';
import { BrowserStorage } from '@src/core/domain/storage/BrowserStorage';
import { SharePrivateServiceRequestStepData } from '@src/ui/apps/ServiceRequest/Steps/SharePrivateServiceRequestStep/SharePrivateServiceRequestStep';
import { NullBrowserStorage } from '@src/core/domain/storage/NullBrowserStorage';
import LogRocket from 'logrocket';

export type FormData = UploadPhotosData &
  JobTypeStepData &
  LocationStepData &
  QuestionStepsData &
  DescriptionStepData &
  CustomerContactStepData &
  SharePrivateServiceRequestStepData;

export type ServiceRequestFormHook = usePhotoUploadMethods & {
  state: ServiceRequestFormState;
  goBackHandler: () => void;
  goFirstHandler: () => void;
  handleStepCompleted: (data: Partial<FormData>) => void;
  handleSubmit<T>(lastStepData: T): void;
};

type UploadPhotosData = {
  uploadedPhotos: UploadedPhoto[];
};

export const emptyFormData = (): FormData => ({
  categoryName: '',
  jobTypeId: '',
  jobTypeName: '',
  postalCode: '',
  firstLevelLocation: undefined,
  secondLevelLocation: undefined,
  location: undefined,
  answers: {},
  description: '',
  uploadedPhotos: [],
  customerName: '',
  customerEmail: '',
  customerPhone: '',
  termsAndConditions: false,
  thirdParty: false,
  sharePrivateServiceRequest: '',
});

const FIRST_QUESTION_STEP = 3;

export const initialServiceRequestState: ServiceRequestFormState = {
  errors: {},
  questions: [],
  needsToLoadQuestions: false,
  currentStep: 1,
  totalSteps: 100,
  isLoadingSlug: false,
  isSendingRequest: false,
  formData: emptyFormData(),
  categories: [],
  isFirstQuestion() {
    return this.currentStep === FIRST_QUESTION_STEP;
  },
};

export const emptyFormConfiguration: FormConfiguration = {
  name: 'unnamedServiceRequestForm',
  sourceStartPage: '',
  sourceEndPage: '',
  pagePath: '',
  appVersion: '',
  usePersistedData: false,
  businessId: undefined,
  businessName: undefined,
  serviceSlug: undefined,
  clientId: undefined,
};

function enableLoadCategoryFromSlug(configuration: FormConfiguration): boolean {
  return typeof configuration.serviceSlug === 'string' && !configuration.usePersistedData;
}

export function useServiceRequestForm(
  storage: BrowserStorage = new NullBrowserStorage(),
  formData?: Partial<FormData>,
  stepToShow = 1,
  configuration = emptyFormConfiguration
): ServiceRequestFormHook {
  const storageKey = `serviceRequestForm-${configuration.appVersion}`;
  const cookieData = configuration.usePersistedData && storage.getItem(storageKey);
  const cookieFormData = (cookieData && JSON.parse(cookieData)) as FormData;
  const initialStep =
    configuration.usePersistedData && cookieFormData?.jobTypeId ? FIRST_QUESTION_STEP : stepToShow;
  const initialFormData: FormData = {
    ...cookieFormData,
    ...formData,
  };

  const initialState: ServiceRequestFormState = {
    ...initialServiceRequestState,
    currentStep: initialStep,
    needsToLoadQuestions: !!initialFormData?.jobTypeId,
    isLoadingSlug: enableLoadCategoryFromSlug(configuration),
    formData: {
      ...emptyFormData(),
      ...initialFormData,
    },
  };
  const [state, dispatch] = useReducer(serviceRequestFormStateReducer, initialState);

  useEffect(() => {
    storage.setItem(storageKey, JSON.stringify(state.formData));
  }, [state.formData, storageKey, storage]);

  const { uploads, onFileAdded, removeFile } = usePhotoUpload();

  useEffect(() => {
    FetchAllCategoriesFactory.create()
      .execute()
      .then((categoryTree) =>
        dispatch({ type: Action.CATEGORIES_LOADED, categories: categoryTree })
      );

    if (!enableLoadCategoryFromSlug(configuration)) return;

    if (typeof configuration.serviceSlug !== 'string') {
      return;
    }

    FetchCategoryBySlugFactory.create()
      .execute(configuration.serviceSlug)
      .then((category) => {
        if (category.type === CategoryType.CATEGORY) {
          dispatch({
            type: Action.SET_CATEGORY_FROM_SLUG,
            categoryName: category.name,
          });
        } else if (category.type === CategoryType.JOB_TYPE) {
          dispatch({
            type: Action.SET_JOB_TYPE_FROM_SLUG,
            jobTypeId: category.id,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: Action.INITIALIZATION_FROM_SLUG_FAILED,
          isLoading: false,
        });
        if (error instanceof CategoryNotFoundError) return;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!state.needsToLoadQuestions) {
      return;
    }
    FetchServiceRequestQuestionsFactory.create()
      .execute(state.formData.jobTypeId)
      .then((questions: Question[]) => {
        dispatch({
          type: Action.QUESTIONS_LOADED,
          questions,
        });
        eventBusSingleton.fireEvent(new QuestionsLoaded(questions));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.needsToLoadQuestions]);

  const goBackHandler = (): void => {
    dispatch({ type: Action.GO_BACK_ONE_STEP });
  };

  const goFirstHandler = (): void => {
    dispatch({ type: Action.GO_FIRST_STEP });
  };

  const handleStepCompleted = (newPartialFormData: Partial<FormData>): void => {
    dispatch({
      type: Action.SUBMIT_STEP,
      formData: { ...state.formData, ...newPartialFormData },
    });
  };

  function handleSubmit<CustomerContactStepData>(lastStepData: CustomerContactStepData): void {
    const payload: FormData = {
      ...state.formData,
      ...lastStepData,
    };

    LogRocket.identify(payload.customerEmail, {
      name: payload.customerName,
      email: payload.customerEmail,
    });

    dispatch({
      type: Action.SENDING_FORMDATA,
      formData: payload,
    });

    const locationIdentifier = LocationIdentifier.fromPostalCodeOrLocationId(
      payload.postalCode,
      payload.location ? payload.location.value() : payload.secondLevelLocation
    );

    const { name: formName, sourceStartPage, sourceEndPage, businessId } = configuration;
    const serviceRequestVisibility = businessId
      ? {
          businessId: parseInt(businessId),
          visibleToOtherBusinesses: payload.sharePrivateServiceRequest === 'yes',
        }
      : undefined;

    const serviceRequest: ServiceRequest = {
      jobTypeData: {
        id: payload.jobTypeId,
        name: payload.jobTypeName,
      },
      locationIdentifier,
      visibility: serviceRequestVisibility,
      answers: Object.values(payload.answers),
      description: payload.description,
      photoIds: uploads.completed.map((u) => u.id),
      customerContactData: new CustomerContactData(
        payload.customerName,
        payload.customerEmail,
        payload.customerPhone
      ),
      termsAndConditions: payload.termsAndConditions,
      thirdParty: payload.thirdParty,
      additionalData: {
        sourceMedium: formName,
        sourceStartPage,
        sourceEndPage,
      },
      clientId: configuration.clientId,
    };

    eventBusSingleton.fireEvent(new ServiceRequestFormSubmitted());

    CreateServiceRequestFactory.create()
      .execute(serviceRequest)
      .then((response) => {
        dispatch({
          type: Action.SERVICE_REQUEST_COMPLETED,
          initialState: initialServiceRequestState,
        });

        // to make sure the storage is cleared before the component is unmounted because of the redirection
        storage.removeItem(storageKey);
      })
      .catch((error: HttpError) => {
        dispatch({
          type: Action.FORM_ERROR,
          errors: mapError(error),
        });
      });
  }

  return {
    state,
    goBackHandler,
    goFirstHandler,
    handleStepCompleted,
    handleSubmit,
    uploads,
    onFileAdded,
    removeFile,
  };
}

export default useServiceRequestForm;
