import { AmplitudeClient } from '@src/core/infrastructure/AmplitudeQueuedClient';
import AmplitudeQueuedClient from './infrastructure/AmplitudeQueuedClient';
import { extractServiceSlug } from '@src/ui/helpers/form/slugExtractor';
import { CustomerContactStepData } from '@src/ui/apps/ServiceRequest/Steps/CustomerContactStep/CustomerContactStep';

type Environment = Record<string, string | undefined>;

export type FormRenderingOptions = {
  name: string;
  sourceStartPage: string;
  sourceEndPage: string;
  pagePath: string;
  usePersistedData: boolean;
  businessId: string | undefined;
  businessName: string | undefined;
  serviceSlug: string | undefined;
  hideSharePrivateServiceRequestStep?: boolean;
  showCommercialPhone?: boolean;
  customerContactData?: Omit<CustomerContactStepData, 'termsAndConditions'>;
  clientId?: string;
  enableFindProfessionals?: boolean;
  enableForwardServiceRequest?: boolean;
};

type FormConfiguration = FormRenderingOptions & {
  appVersion: string;
};

class ApplicationConfiguration {
  private _window: Window;
  private _environment: Environment;

  constructor(
    environment: Environment,
    window: Window,
    readonly options?: Partial<FormRenderingOptions>
  ) {
    this._window = window;
    this._environment = environment;
    this.options = options;
  }

  private get formName(): string {
    return this._window._2030?.isModal ? 'modal2030' : 'landing2030';
  }

  get formConfiguration(): FormConfiguration {
    const defaultRenderingOptions = {
      name: `quotation_form/${this.formName}`,
      usePersistedData: this._window._2030?.USE_PERSISTED_DATA || false,
      businessId: this._window._2030?.BUSINESS_ID,
      businessName: undefined,
      sourceStartPage: this._window._2030?.SOURCE_START_PAGE || 'quotation-landingExternal',
      sourceEndPage: this._window._2030?.SOURCE_END_PAGE || 'quotation-landingExternal',
      pagePath: this._window._2030?.PAGE_PATH || window.location.pathname,
      serviceSlug: extractServiceSlug(),
    };

    return {
      ...defaultRenderingOptions,
      ...this.options,
      appVersion: this.appVersion,
    };
  }

  get amplitudeClient(): AmplitudeClient {
    return new AmplitudeQueuedClient(() => {
      const amplitude = window._2030?.amplitude || window.amplitude;
      const projectName = this._environment.AMPLITUDE_PROJECT_NAME || '2030-default-project-name';
      return amplitude?.getInstance(projectName);
    });
  }

  get isUserTrackingEnabled(): boolean {
    const isUserTrackingEnabledAsString = this._environment.ENABLE_USER_TRACKING || '';

    return isUserTrackingEnabledAsString.toLowerCase() === 'true';
  }

  get country(): string {
    const [, country] = this.locale?.split('-') || [];

    return (country || 'es').toLocaleLowerCase();
  }

  get locale(): undefined | string {
    return this._environment.LOCALE;
  }

  get appVersion(): string {
    return this._environment.APP_VERSION || 'development';
  }
}

export default ApplicationConfiguration;
export type { FormConfiguration };
