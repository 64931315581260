import React, { ChangeEvent, FocusEvent, PropsWithChildren } from 'react';
import InputStatus from '@src/ui/helpers/form/InputStatus';
import styles from './Input.module.scss';
import cx from 'classnames';

export type SelectProps = {
  name: string;
  inputRef?: (ref: HTMLSelectElement | null) => void;
  id?: string;
  type?: string;
  value?: string;
  defaultValue?: string;
  status?: InputStatus;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (focusEvent: FocusEvent<HTMLSelectElement>) => void;
  onKeyPress?: (keyPressEvent: React.KeyboardEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
  'data-testid'?: string;
};

function Select(props: PropsWithChildren<SelectProps>): React.ReactElement {
  const inputStatus = props.status;
  const className = cx(styles.select, styles['input--select'], styles[`input--${props.type}`], {
    [styles['input--error']]: inputStatus === InputStatus.ERROR,
    [styles['input--success']]: inputStatus === InputStatus.SUCCESS,
  });

  return (
    <select
      ref={props.inputRef}
      name={props.name}
      id={props.id}
      value={props.value}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onKeyPress={props.onKeyPress}
      className={className}
      disabled={props.disabled}
      data-testid={props['data-testid']}
    >
      {props.children}
    </select>
  );
}

export default Select;
