import HttpResponse from './HttpResponse';
import HttpError from './HttpError';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { HttpClientInterface } from '@src/core/infrastructure/http/HttpClientInterface';

export default class HttpClient implements HttpClientInterface {
  get<T>(url: string): Promise<HttpResponse<T>> {
    return axios
      .get(url)
      .then((axiosResponse: AxiosResponse) => {
        return new HttpResponse<T>(axiosResponse.status, axiosResponse.data);
      })
      .catch(this.throwHttpError);
  }

  post<T>(url: string, data: unknown, config: AxiosRequestConfig = {}): Promise<HttpResponse<T>> {
    return axios
      .post(url, data, { withCredentials: true, ...config })
      .then((axiosResponse: AxiosResponse) => {
        return new HttpResponse<T>(axiosResponse.status, axiosResponse.data);
      })
      .catch(this.throwHttpError);
  }

  private throwHttpError(error: AxiosError): never {
    if (error.response) {
      if (error.response.data?.code && error.response.data?.message) {
        throw new HttpError(error.response.data.code, error.response.data.message);
      } else {
        throw new HttpError(error.response.status.toString(), JSON.stringify(error.response.data));
      }
    } else if (error.request) {
      throw new HttpError('NETWORK_ERROR', error.message);
    }

    throw new HttpError(
      error.code || 'ANY_CODE',
      '¡Ups! Ha ocurrido un error inesperado, inténtalo de nuevo'
    );
  }
}
