import React, { ChangeEvent, createRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ServiceRequestFormContext } from '@src/ui/apps/ServiceRequest/ServiceRequestFormContext';
import StepTitle from '@src/ui/apps/ServiceRequest/Steps/StepTitle';
import { FormattedMessage } from 'react-intl';
import ButtonLabel from '@src/ui/components/ButtonLabel/ButtonLabel';
import styles from '@src/ui/apps/ServiceRequest/Steps/QuestionStep/QuestionStep.module.scss';
import Input from '@src/ui/components/Input/Input';
import { adaptValidator, notEmptyValidation } from '@src/ui/helpers/form/validations';
import ValidationError from '@src/ui/helpers/form/ValidationError';
import { useFormHelper } from '@src/ui/helpers/form/ReactHookFormHelper';
import { eventBusSingleton } from '@src/core/infrastructure/Events/EventBus';
import {
  SharePrivateServiceRequestClickedReject,
  SharePrivateServiceRequestClickedShare,
  SharePrivateServiceRequestStepRendered,
} from '@src/ui/apps/ServiceRequest/ServiceRequestEvents';

export type SharePrivateServiceRequestStepData = {
  sharePrivateServiceRequest: string;
};

type SharePrivateServiceRequestStepProps = {
  formControls?: React.ReactChild;
};

function SharePrivateServiceRequestStep({
  formControls,
}: SharePrivateServiceRequestStepProps): React.ReactElement {
  const { formData, handleStepCompleted } = React.useContext(ServiceRequestFormContext);
  const form = useForm<SharePrivateServiceRequestStepData>({});
  const helper = useFormHelper(form);
  const refForm = createRef<HTMLFormElement>();

  useEffect(() => {
    eventBusSingleton.fireEvent(new SharePrivateServiceRequestStepRendered());
  }, []);

  return (
    <form
      onSubmit={form.handleSubmit(handleStepCompleted)}
      data-testid="sharePrivateServiceRequestForm"
      ref={refForm}
    >
      <StepTitle className="step-title" data-testid="step-title">
        <FormattedMessage
          id="serviceRequestForm.sharePrivateServiceRequestStep.header"
          defaultMessage="¿Quieres ahorrar hasta un 20% comparando presupuestos?"
          description="Título del paso para escoger si el usuario quiere compartir la solicitud de presupuesto con otros profesionales para recibir más presupuestos"
        />
      </StepTitle>

      {helper.errorMessage('sharePrivateServiceRequest') && (
        <ValidationError>{helper.errorMessage('sharePrivateServiceRequest')}</ValidationError>
      )}

      <ButtonLabel className={styles.QuestionStep__entry}>
        <label htmlFor="sharePrivateServiceRequestYes">
          <Input
            inputRef={form.register({ validate: adaptValidator(notEmptyValidation) })}
            type="radio"
            name="sharePrivateServiceRequest"
            id="sharePrivateServiceRequestYes"
            value="yes"
            defaultChecked={formData.sharePrivateServiceRequest === 'yes'}
            data-testid="answerOptionYes"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              eventBusSingleton.fireEvent(new SharePrivateServiceRequestClickedShare());
              handleStepCompleted(form.getValues() as SharePrivateServiceRequestStepData);
            }}
          />
          <FormattedMessage
            id="serviceRequestForm.sharePrivateServiceRequestStep.option.yes"
            defaultMessage="Sí, quiero comparar hasta 3 presupuestos más"
            description="Respuesta afirmativa a la solicitud de presupuesto con otros profesionales para recibir más presupuestos"
          />
        </label>
      </ButtonLabel>

      <ButtonLabel className={styles.QuestionStep__entry}>
        <label htmlFor="sharePrivateServiceRequestNo">
          <Input
            inputRef={form.register({ validate: adaptValidator(notEmptyValidation) })}
            type="radio"
            name="sharePrivateServiceRequest"
            id="sharePrivateServiceRequestNo"
            value="no"
            defaultChecked={formData.sharePrivateServiceRequest === 'no'}
            data-testid="answerOptionNo"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              eventBusSingleton.fireEvent(new SharePrivateServiceRequestClickedReject());
              handleStepCompleted(form.getValues() as SharePrivateServiceRequestStepData);
            }}
          />
          <FormattedMessage
            id="serviceRequestForm.sharePrivateServiceRequestStep.option.no"
            defaultMessage="No me interesa por el momento"
            description="Respuesta negativa a la solicitud de presupuesto con otros profesionales para recibir más presupuestos"
          />
        </label>
      </ButtonLabel>

      {formControls}
    </form>
  );
}

export default SharePrivateServiceRequestStep;
