import React, { useState } from 'react';
import styles from './FormWizard.module.scss';

export const INITIAL_STEP = 1;

type Navigation = {
  step: number;
  goNext: () => void;
  goBack: () => void;
  goToStep: (stepNumber: number) => void;
};

const useNavigation = (stepToShow: number = INITIAL_STEP): Navigation => {
  const [step, setStep] = useState(stepToShow);
  const goNext = (): void => {
    setStep(step + 1);
  };

  const goBack = (): void => {
    setStep(step - 1);
  };

  const goToStep = (stepNumber: number): void => {
    setStep(stepNumber);
  };

  return { step, goNext, goBack, goToStep };
};

type FormWizardProps = {
  step: number;
  setSteps: (steps: number) => void;
  children: React.ReactNode;
};

function FormWizard(props: FormWizardProps): React.ReactElement {
  const children = React.Children.toArray(props.children).filter((child) => !!child);

  props.setSteps(children.length);

  const currentStep = children.map((child, index) => (
    <div key={index} className={styles.entering}>
      {child}
    </div>
  ))[props.step - INITIAL_STEP];

  return currentStep || null;
}

FormWizard.defaultProps = {
  setSteps: (steps: number) => {
    void 0;
  },
};

export default FormWizard;
export { useNavigation };
export type { Navigation };
