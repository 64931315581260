import React from 'react';
import styles from './CheckBox.module.scss';

type CheckBoxProps = {
  inputRef?: (ref: HTMLInputElement | null) => void;
  inputId?: string;
  defaultChecked?: boolean;
  name: string;
  label: string | React.ReactElement;
  'data-testid'?: string;
};

function CheckBox(props: CheckBoxProps): React.ReactElement {
  return (
    <label htmlFor={props.inputId} className={styles.CheckBox__label}>
      <input
        type="checkbox"
        id={props.inputId}
        name={props.name}
        className={styles.CheckBox__input}
        ref={props.inputRef}
        defaultChecked={props.defaultChecked}
        data-testid={props['data-testid']}
      />
      {props.label}
    </label>
  );
}

export default CheckBox;
