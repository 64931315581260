import React from 'react';
import { LocationStepRenderProps } from '@src/ui/apps/ServiceRequest/Steps/LocationStep/LocationStep';
import Link from '@src/ui/components/Link/Link';
import { FormattedMessage } from 'react-intl';
import PostalCodeField from '@src/ui/apps/ServiceRequest/Steps/LocationStep/PostalCodeField';
import styles from '@src/ui/apps/ServiceRequest/Steps/LocationStep/BrazilianLocationStepRenderer.module.scss';

function BrazilianLocationStepRenderer({
  form,
  formData,
}: LocationStepRenderProps): React.ReactElement {
  return (
    <div className={styles.locationStepRenderer}>
      <PostalCodeField form={form} formData={formData} mask="00000-000" />
      <small>
        <Link href="http://www.buscacep.correios.com.br/sistemas/buscacep/" target="_blank">
          <FormattedMessage
            id="serviceRequestForm.locationStep.switchToSearchLocation"
            defaultMessage="¿No lo recuerdas?"
            description="Texto del link que permite al usuario ir a buscar su población si no recuerda su código postal (o equivalente)"
          />
          &raquo;
        </Link>
      </small>
    </div>
  );
}

export default BrazilianLocationStepRenderer;
