import React from 'react';
import BackButton from '@src/ui/apps/ServiceRequest/BackButton';
import ButtonGroup from '@src/ui/apps/ServiceRequest/ButtonGroup';
import NextButton from '@src/ui/apps/ServiceRequest/NextButton';

type MiddleStepFormControlsProps = {
  goBack: () => void;
};

export function MiddleStepFormControls({
  goBack,
}: MiddleStepFormControlsProps): React.ReactElement {
  return (
    <ButtonGroup>
      <BackButton goBack={goBack} />
      <NextButton />
    </ButtonGroup>
  );
}
