import React from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonGroup from '@src/ui/apps/ServiceRequest/ButtonGroup';
import BackButton from '@src/ui/apps/ServiceRequest/BackButton';
import Button from '@src/ui/components/Button/Button';

type FinalStepFormControlsProps = {
  goBack: () => void;
  isSubmitting: boolean;
};

const FinalStepFormControls = ({
  goBack,
  isSubmitting,
}: FinalStepFormControlsProps): React.ReactElement => {
  return (
    <ButtonGroup>
      <BackButton goBack={goBack} disabled={isSubmitting} />
      <Button type="submit" isLoading={isSubmitting} data-testid="submitButton">
        <FormattedMessage
          id="serviceRequestForm.customerContactStep.submitButton"
          defaultMessage="Enviar"
          description="Texto que aparece en el botón de enviar formulario"
        />
      </Button>
    </ButtonGroup>
  );
};

export default FinalStepFormControls;
