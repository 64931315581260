import React from 'react';
import Link from '@src/ui/components/Link/Link';
import I18n from '@src/core/I18n';

function TermsOfUseLink(props: { linkText: string }): React.ReactElement {
  const LOCALE = I18n.intl().locale;

  switch (LOCALE) {
    case 'es-ES':
      return (
        <Link href="https://www.habitissimo.es/terminos_de_uso" target="_blank">
          {props.linkText}
        </Link>
      );
    case 'it-IT':
      return (
        <Link href="https://www.habitissimo.it/termini_di_utilizzo" target="_blank">
          {props.linkText}
        </Link>
      );
    case 'pt-PT':
      return (
        <Link href="https://www.habitissimo.pt/terminos_de_uso" target="_blank">
          {props.linkText}
        </Link>
      );
    case 'pt-BR':
      return (
        <Link href="https://www.habitissimo.com.br/termos_de_uso" target="_blank">
          {props.linkText}
        </Link>
      );
    case 'es-MX':
      return (
        <Link href="https://www.habitissimo.com.mx/terminos_de_uso" target="_blank">
          {props.linkText}
        </Link>
      );
    case 'es-CL':
      return (
        <Link href="https://www.habitissimo.cl/terminos_de_uso" target="_blank">
          {props.linkText}
        </Link>
      );
    default:
      throw new Error('Country has no terms url');
  }
}

export default TermsOfUseLink;
