import React, { PropsWithChildren } from 'react';
import styles from './StepTitle.module.scss';

type StepTitleProps = {
  className?: string;
  'data-testid'?: string;
};

function StepTitle(props: PropsWithChildren<StepTitleProps>): React.ReactElement {
  return (
    <h3 className={`${styles.StepTitle} ${props.className}`} data-testid={props['data-testid']}>
      {props.children}
    </h3>
  );
}

export default StepTitle;
