import React, { ChangeEvent, FocusEvent } from 'react';
import InputStatus from '@src/ui/helpers/form/InputStatus';
import Input from '@src/ui/components/Input/Input';
import FormField, { FormFieldProps } from '@src/ui/components/FormField/FormField';
import { ReferenceableComponent } from '@src/ui/helpers/masks/withMask';

type InputProxyProps = ReferenceableComponent & {
  inputName: string;
  inputValue?: string;
  inputDefaultValue?: string;
  inputType?: string;
  inputOnChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  inputOnBlur?: (focusEvent: FocusEvent<HTMLInputElement>) => void;
  inputOnKeyPress?: (keyPressEvent: React.KeyboardEvent<HTMLInputElement>) => void;
  inputOnKeyUp?: (keyPressEvent: React.KeyboardEvent<HTMLInputElement>) => void;
  inputStatus?: InputStatus;
};

export type LabelledInputProps = InputProxyProps & FormFieldProps;

function LabelledInput(props: LabelledInputProps): React.ReactElement {
  return (
    <FormField
      inputId={props.inputId}
      labelText={props.labelText}
      inputExample={props.inputExample}
      errorMessage={props.errorMessage}
    >
      <Input
        status={props.inputStatus}
        inputRef={props.inputRef}
        type={props.inputType || 'text'}
        name={props.inputName}
        id={props.inputId}
        value={props.inputValue}
        defaultValue={props.inputDefaultValue}
        onChange={props.inputOnChange}
        onBlur={props.inputOnBlur}
        onKeyPress={props.inputOnKeyPress}
        onKeyUp={props.inputOnKeyUp}
        data-testid={props['data-testid']}
      />
    </FormField>
  );
}

export default LabelledInput;
