import React, { PropsWithChildren } from 'react';
import styles from './StepSubTitle.module.scss';

type StepSubTitleProps = {
  className?: string;
  'data-testid'?: string;
};

function StepSubTitle(props: PropsWithChildren<StepSubTitleProps>): React.ReactElement {
  return (
    <p className={`${styles.StepSubTitle} ${props.className}`} data-testid={props['data-testid']}>
      {props.children}
    </p>
  );
}

export default StepSubTitle;
