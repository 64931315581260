import styles from '@src/ui/components/FormField/FormField.module.scss';
import labelStyles from '@src/ui/components/Label/Label.module.scss';
import ValidationError from '@src/ui/helpers/form/ValidationError';
import EmailDomainAutocomplete from '@src/ui/apps/ServiceRequest/Steps/CustomerContactStep/EmailDomainAutocomplete';
import EmailDomainAutocompleteOption from '@src/ui/apps/ServiceRequest/Steps/CustomerContactStep/EmailDomainAutocompleteOption';
import Input from '@src/ui/components/Input/Input';
import {
  adaptValidator,
  combine,
  emailValidation,
  notEmptyValidation,
} from '@src/ui/helpers/form/validations';
import React, { ReactElement, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useFormHelper } from '@src/ui/helpers/form/ReactHookFormHelper';
import { UseFormMethods } from 'react-hook-form/dist/types';
import { CustomerContactStepData } from '@src/ui/apps/ServiceRequest/Steps/CustomerContactStep/CustomerContactStep';
import { FormData } from '@src/ui/apps/ServiceRequest/useServiceRequestForm';

export type EmailAutocompleteProps = {
  form: UseFormMethods<CustomerContactStepData>;
  formData: Partial<FormData>;
};

function EmailAutocomplete({ form, formData }: EmailAutocompleteProps): ReactElement {
  const intl = useIntl();
  const helper = useFormHelper(form);

  const [selectedEmail, setSelectedEmail] = useState('');
  useEffect(() => {
    if (formData.customerEmail) {
      setSelectedEmail(formData.customerEmail);
    }
  }, []);

  return (
    <div className={styles.formField} data-testid="emailAutocompleteTypeahead">
      <label id="customerEmailLabel" className={labelStyles.label}>
        {intl.formatMessage({
          id: 'serviceRequestForm.customerContactStep.emailField.label',
          defaultMessage: 'Email',
          description: 'Texto para indicar el campo Email de los datos de contacto del usuario',
        })}
      </label>

      {helper.errorMessage('customerEmail') && (
        <ValidationError>{helper.errorMessage('customerEmail')}</ValidationError>
      )}
      <EmailDomainAutocomplete
        onChange={(option?) => {
          if (option) {
            setSelectedEmail(option.value());
          }
        }}
        onBlur={(e) => {
          const eventTarget = e.target as HTMLInputElement;
          if (eventTarget && eventTarget.value) {
            setSelectedEmail(eventTarget.value);
          }
        }}
        inputStatus={helper.inputStatus('customerEmail')}
        defaultSelected={new EmailDomainAutocompleteOption(selectedEmail)}
      />

      <Input
        aria-labelledby="customerEmailLabel"
        name="customerEmail"
        id="customerEmail"
        type="hidden"
        data-testid="emailAutocomplete"
        inputRef={form.register({
          validate: adaptValidator(combine(notEmptyValidation, emailValidation)),
        })}
        value={selectedEmail}
        status={helper.inputStatus('customerEmail')}
      />
    </div>
  );
}

export default EmailAutocomplete;
