import React, { FunctionComponent, useEffect, useRef } from 'react';
import LabelledInput, { LabelledInputProps } from '@src/ui/components/LabelledInput/LabelledInput';
import {
  adaptValidator,
  combine,
  notEmptyValidation,
  phoneNumberValidation,
  termsAcceptedValidation,
} from '@src/ui/helpers/form/validations';
import ValidationError from '@src/ui/helpers/form/ValidationError';
import Alert, { AlertTypes } from '@src/ui/components/Alert/Alert';
import CheckBox from '@src/ui/components/Input/CheckBox';
import StepTitle from '@src/ui/apps/ServiceRequest/Steps/StepTitle';
import StepSubTitle from '@src/ui/apps/ServiceRequest/Steps/StepSubTitle';
import { eventBusSingleton } from '@src/core/infrastructure/Events/EventBus';
import { ContactDataStepRendered } from '@src/ui/apps/ServiceRequest/ServiceRequestEvents';
import Icon from '@src/ui/components/Icon/Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import PrivacyPolicyLink from './PrivacyPolicyLink';
import TermsOfUseLink from './TermsOfUseLink';
import { ServiceRequestFormContext } from '@src/ui/apps/ServiceRequest/ServiceRequestFormContext';
import { Controller, useForm } from 'react-hook-form';
import { useFormHelper } from '@src/ui/helpers/form/ReactHookFormHelper';
import { getLocalizedService } from '@src/ui/helpers/localization/index';
import { Mask, withMask } from '@src/ui/helpers/masks/withMask';
import EmailAutocomplete from '@src/ui/components/EmailAutocomplete/EmailAutocomplete';

export type CustomerContactStepData = {
  customerName: string;
  customerEmail: string;
  customerPhone: string;
  termsAndConditions: boolean;
  thirdParty: boolean;
};
function isKeyOfCustomerContactStepData(
  keyInput: string
): keyInput is keyof CustomerContactStepData {
  return ['customerName', 'customerEmail', 'customerPhone', 'termsAndConditions'].includes(
    keyInput
  );
}

type CustomerContactStepProps = {
  formControls?: React.ReactChild;
  errors?: Record<string, string>;
  uploadingPhotosCount?: number;
};

const MaskedLabelledInput = withMask<LabelledInputProps>(LabelledInput);

export const CustomerContactStep: FunctionComponent<CustomerContactStepProps> = ({
  formControls,
  errors = {},
  uploadingPhotosCount = 0,
}: CustomerContactStepProps) => {
  const form = useForm<CustomerContactStepData>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });
  const helper = useFormHelper(form);
  const { formData, handleSubmit } = React.useContext(ServiceRequestFormContext);
  const ref = useRef<HTMLElement>();

  useEffect(() => {
    eventBusSingleton.fireEvent(new ContactDataStepRendered());
  }, []);

  useEffect(() => {
    Object.entries(errors || {}).forEach(([field, error]) => {
      if (isKeyOfCustomerContactStepData(field)) {
        helper.setError(field as keyof CustomerContactStepData, error);
      }
    });
  }, [errors]);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  const intl = useIntl();
  const anyCharacter = { mask: /.*/ };
  const phoneMaskProps = getLocalizedService<Mask>('phoneMaskProps') || anyCharacter;

  return (
    <form id="form-estimate" onSubmit={form.handleSubmit(handleSubmit)}>
      <StepTitle className="step-title">
        <FormattedMessage
          id="serviceRequestForm.customerContactStep.header"
          defaultMessage="Tus datos de contacto"
          description="Título del paso para que el usuario entre sus datos de contacto"
        />
      </StepTitle>
      <StepSubTitle className="step-sub-title">
        <FormattedMessage
          id="serviceRequestForm.customerContactStep.subTitle"
          defaultMessage="Para receber os orçamentos dos nossos profissionais"
          description="Subtitulo para step de contato"
        />
      </StepSubTitle>
      <LabelledInput
        inputRef={(r) => {
          form.register(r, { validate: adaptValidator(notEmptyValidation) });
          ref.current = r;
        }}
        labelText={intl.formatMessage({
          id: 'serviceRequestForm.customerContactStep.nameField.label',
          defaultMessage: 'Nombre',
          description: 'Texto para indicar el campo Nombre de los datos de contacto del usuario',
        })}
        inputName="customerName"
        inputId="customerName"
        inputStatus={helper.inputStatus('customerName')}
        errorMessage={helper.errorMessage('customerName')}
        inputDefaultValue={formData.customerName}
        data-testid="customerName"
      />

      <EmailAutocomplete form={form} formData={formData} />

      <Controller
        name="customerPhone"
        control={form.control}
        rules={{ validate: adaptValidator(combine(notEmptyValidation, phoneNumberValidation)) }}
        defaultValue={formData?.customerPhone || ''}
        render={({ onBlur, onChange, value }) => (
          <MaskedLabelledInput
            {...phoneMaskProps}
            inputType="tel"
            labelText={intl.formatMessage({
              id: 'serviceRequestForm.customerContactStep.phoneField.label',
              defaultMessage: 'Teléfono',
              description:
                'Texto para indicar el campo Teléfono de los datos de contacto del usuario',
            })}
            onAccept={(newValue: string) => {
              onChange(newValue);
            }}
            onComplete={(_) => {
              onBlur();
            }}
            value={value}
            inputOnBlur={onBlur}
            inputName="customerPhone"
            inputId="customerPhone"
            inputStatus={helper.inputStatus('customerPhone')}
            errorMessage={helper.errorMessage('customerPhone')}
            data-testid="customerPhone"
          />
        )}
      />

      <Alert variant={AlertTypes.WARNING}>
        <span>
          <FormattedMessage
            id="serviceRequestForm.customerContactStep.verification.contactAlert"
            // disable formatjs placeholders rules because it throws an error due to defaultRichTextElements
            // eslint-disable-next-line formatjs/enforce-placeholders
            defaultMessage="Comprueba que tus datos son correctos ya que <b>los profesionales pagan una cuota</b> para poder acceder a tu solicitud de presupuesto."
            description="Mensaje informativo de que el usuario debe introducir datos válidos"
          />
        </span>
      </Alert>

      <div>
        <CheckBox
          inputRef={form.register({ validate: adaptValidator(termsAcceptedValidation) })}
          inputId="termsAndConditions"
          name="termsAndConditions"
          data-testid="termsAndConditions"
          defaultChecked={formData.termsAndConditions}
          label={
            <FormattedMessage
              id="serviceRequestForm.customerContactStep.termsAndConditions.label"
              defaultMessage="He leído y acepto la <privacyPolicy>Política de Privacidad</privacyPolicy> y las <termsOfUse>Condiciones de uso</termsOfUse>"
              description="Mensaje de aceptación de términos y condiciones"
              values={{
                privacyPolicy: function privacyPolicy(linkText: string) {
                  return <PrivacyPolicyLink linkText={linkText} />;
                },
                termsOfUse: function termsOfUse(linkText: string) {
                  return <TermsOfUseLink linkText={linkText} />;
                },
              }}
            />
          }
        />

        {helper.hasError('termsAndConditions') && (
          <ValidationError>{helper.errorMessage('termsAndConditions')}</ValidationError>
        )}
      </div>
      <div>
        <CheckBox
          inputRef={form.register({})}
          inputId="thirdParty"
          name="thirdParty"
          data-testid="thirdParty"
          defaultChecked={formData.thirdParty}
          label={
            <FormattedMessage
              id="serviceRequestForm.customerContactStep.thirdParty.label"
              defaultMessage="Deseo recibir información, promociones y ofertas comerciales de terceros"
              description="Mensaje de aceptación de comunicación con terceros"
            />
          }
        />
      </div>
      {uploadingPhotosCount > 0 && (
        <Alert variant={AlertTypes.WARNING} icon={<Icon name="refresh-o" animate={true} />}>
          <span data-testid="uploadingPhotosAlert">
            <FormattedMessage
              id="serviceRequestForm.customerContactStep.uploadingPhotos.warning"
              defaultMessage="{numberOfUploadingPhotos, plural, one {Hay # imagen en proceso de ser subida. Si envías la solicitud ahora la imagen no será incluida, pero podrás añadirla más adelante.} other {Hay # imágenes en proceso de ser subidas. Si envías la solicitud ahora algunas imágenes no serán incluidas, pero podrás añadirlas más adelante.} }"
              description="Mensaje de aviso de que aún se están subiendo las fotos y que puede perderlas si envía el formulario."
              values={{ numberOfUploadingPhotos: uploadingPhotosCount }}
            />
          </span>
        </Alert>
      )}
      {formControls}
    </form>
  );
};
