import PostalCodeValidator from '@src/core/domain/PostalCode/PostalCodeValidator';
import InvalidPostalCodeError from '@src/core/domain/PostalCode/InvalidPostalCodeError';

class BrazilianPostalCodeValidator implements PostalCodeValidator {
  validate(postalCode: string): void {
    const pattern = new RegExp(`^[0-9]{5}-[0-9]{3}$`);

    const isInvalidPostalCode = pattern.test(postalCode);

    if (!isInvalidPostalCode) {
      throw new InvalidPostalCodeError();
    }
  }
}

export default BrazilianPostalCodeValidator;
