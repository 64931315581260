import PhoneNumberValidator from '@src/core/domain/CustomerContactData/PhoneNumberValidator';
import InvalidPhoneNumberError from '@src/core/domain/CustomerContactData/InvalidPhoneNumberError';

class BrazilianPhoneNumberValidator implements PhoneNumberValidator {
  validate(phoneNumber: string): void {
    const pattern = new RegExp(/^\d{2}9?\d{8}$/);
    const phoneNumberWithoutBlanksOrSpacialChars = phoneNumber.replace(/[\s-)(]+/g, '');

    const isValidPhoneNumber = pattern.test(phoneNumberWithoutBlanksOrSpacialChars);

    if (!isValidPhoneNumber) {
      throw new InvalidPhoneNumberError();
    }
    return;
  }
}

export default BrazilianPhoneNumberValidator;
