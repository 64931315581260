import React from 'react';
import styles from './Input.module.scss';
import cx from 'classnames';

type TextareaProps = {
  name: string;
  rows?: number;
  cols?: number;
  id?: string;
  placeholder?: string;
  inputRef?: (ref: HTMLTextAreaElement) => void;
  'data-testid'?: string;
  defaultValue?: string;
};

function Textarea(props: TextareaProps): JSX.Element {
  return (
    <textarea
      ref={props.inputRef}
      className={cx(styles.input, styles['input--textarea'])}
      rows={props.rows}
      cols={props.cols}
      name={props.name}
      id={props.id}
      placeholder={props.placeholder}
      data-testid={props['data-testid']}
      defaultValue={props.defaultValue}
    ></textarea>
  );
}

Textarea.defaultProps = {
  rows: 7,
  cols: 30,
};

export default Textarea;
