import React, { useEffect, useState, memo } from 'react';

import { InitStep } from './InitStep';
import { QuestionsStep } from './QuestionsStep';

const dictSteps = {
  0: InitStep,
  1: QuestionsStep,
};

type QuestionsStepProps = {
  goBackStepsToCloseForm: () => void;
};

function StepsCloseFormComponent({
  goBackStepsToCloseForm,
}: QuestionsStepProps): React.ReactElement {
  const [step, setStep] = useState<number>(0);

  const HandleStepComponent = dictSteps[step];

  useEffect(() => {
    // FIXME: close button inside monolitic project
    const closeButton = document.getElementsByClassName('bootbox-close-button close');

    if (closeButton?.length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      closeButton[0].style.visibility = 'hidden';
    }

    return () => {
      if (closeButton?.length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        closeButton[0].style.visibility = 'visible';
      }
    };
  }, []);

  return (
    <HandleStepComponent
      key={step}
      nextStep={() => setStep(step + 1)}
      goBackStepsToCloseForm={goBackStepsToCloseForm}
    />
  );
}

export const StepsCloseForm = memo(StepsCloseFormComponent);
