/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Loading from '@src/ui/components/Loading/Loading';
import ProgressBar from '@src/ui/components/ProgressBar/ProgressBar';
import { ServiceRequestFormState } from '@src/ui/apps/ServiceRequest/serviceRequestFormReducer';
import { scrollerFunction, scrollIntoForm } from '@src/ui/apps/ServiceRequest/Steps/utils';
import styles from '@src/ui/apps/ServiceRequest/ServiceRequestForm.module.scss';
import { usePhotoUploadMethods } from '@src/ui/apps/ServiceRequest/hooks/usePhotoUpload';
import FormWizard from '@src/ui/apps/ServiceRequest/FormWizard';
import JobTypeStep from '@src/ui/apps/ServiceRequest/Steps/JobTypeStep/JobTypeStep';
import LocationStep from '@src/ui/apps/ServiceRequest/Steps/LocationStep/LocationStep';
import CategoryGroupTree from '@src/core/domain/Categories/CategoryGroupTree';
import { MiddleStepFormControls } from '@src/ui/apps/ServiceRequest/MiddleStepFormControls';
import { ChangeJobTypeLink } from '@src/ui/apps/ServiceRequest/ChangeJobTypeLink';
import QuestionStep from '@src/ui/apps/ServiceRequest/Steps/QuestionStep/QuestionStep';
import SharePrivateServiceRequestStep from '@src/ui/apps/ServiceRequest/Steps/SharePrivateServiceRequestStep/SharePrivateServiceRequestStep';
import DescriptionStep from '@src/ui/apps/ServiceRequest/Steps/DescriptionStep/DescriptionStep';
import { CustomerContactStep } from '@src/ui/apps/ServiceRequest/Steps/CustomerContactStep/CustomerContactStep';
import Alert, { AlertTypes } from '@src/ui/components/Alert/Alert';
import FreeAndWithoutObligation from '@src/ui/apps/ServiceRequest/FreeAndWithoutObligation';
import FinalStepFormControls from '@src/ui/apps/ServiceRequest/FinalStepFormControls';
import CommercialPhone from '@src/ui/apps/ServiceRequest/CommercialPhone';
import { StepsCloseForm } from './StepsCloseForm';

export type ServiceRequestFormRendererProps = usePhotoUploadMethods & {
  state: ServiceRequestFormState;
  goBackHandler: () => void;
  goFirstHandler: () => void;
  businessId?: string;
  hideSharePrivateServiceRequestStep?: boolean;
  showCommercialPhone?: boolean;
  scrollFunction?: scrollerFunction;
  header?: React.ReactElement;
};

function ServiceRequestFormRenderer({
  state,
  goBackHandler,
  goFirstHandler,
  uploads,
  onFileAdded,
  removeFile,
  businessId,
  hideSharePrivateServiceRequestStep,
  showCommercialPhone,
  scrollFunction = scrollIntoForm,
  header,
}: ServiceRequestFormRendererProps): React.ReactElement {
  const showLoader =
    state.isLoadingSlug ||
    state.categories.length === 0 ||
    (state.needsToLoadQuestions && state.currentStep > 2);

  const formRef = useRef<HTMLDivElement>(null);

  const [showStepsToCloseForm, setShowStepsToCloseForm] = useState<boolean>(false);

  const isInitialStep = useRef(true);
  useEffect(() => {
    if (isInitialStep.current === true) {
      isInitialStep.current = false;
      return;
    }
    scrollFunction(formRef);
  }, [state.currentStep]);

  useEffect(() => {
    state.errors.global = '';
  }, [goBackHandler]);

  useEffect(() => {
    document.addEventListener('serviceRequestForm-showStepsToCloseForm', () =>
      setShowStepsToCloseForm(true)
    );

    return () => {
      document.removeEventListener('serviceRequestForm-showStepsToCloseForm', () =>
        setShowStepsToCloseForm(false)
      );
    };
  }, []);

  const ShowComponentsHandler = () => {
    if (showStepsToCloseForm) {
      return <StepsCloseForm goBackStepsToCloseForm={() => setShowStepsToCloseForm(false)} />;
    }

    if (showLoader) {
      return <Loading />;
    }

    return (
      <>
        <ProgressBar
          progress={state.currentStep / state.totalSteps}
          className={styles.serviceRequestForm__progressBar}
        />

        <div className={styles.serviceRequestForm__header} data-testid="serviceRequestFormHeader">
          {header}
        </div>

        <FormWizard step={state.currentStep}>
          <JobTypeStep categoriesTree={new CategoryGroupTree(state.categories)} />
          <LocationStep
            formControls={<MiddleStepFormControls goBack={goBackHandler} />}
            changeJobTypeLink={<ChangeJobTypeLink onClick={goFirstHandler} />}
          />
          {state.questions.map((question, index) => (
            <QuestionStep
              key={question.id}
              formControls={<MiddleStepFormControls goBack={goBackHandler} />}
              question={question}
              changeJobTypeLink={<ChangeJobTypeLink onClick={goFirstHandler} />}
            />
          ))}
          {businessId && !hideSharePrivateServiceRequestStep && (
            <SharePrivateServiceRequestStep
              formControls={<MiddleStepFormControls goBack={goBackHandler} />}
            />
          )}
          <DescriptionStep
            uploads={uploads}
            formControls={<MiddleStepFormControls goBack={goBackHandler} />}
            onFilesAdded={onFileAdded}
            removeFile={removeFile}
          />
          <CustomerContactStep
            errors={state.errors}
            uploadingPhotosCount={uploads.uploading.length}
            formControls={
              <FinalStepFormControls goBack={goBackHandler} isSubmitting={state.isSendingRequest} />
            }
          />
        </FormWizard>

        {state.errors.unauthorized && (
          <Alert variant={AlertTypes.ERROR}>
            <span>{state.errors.unauthorized}</span>
          </Alert>
        )}

        {state.errors.global && (
          <Alert variant={AlertTypes.ERROR}>
            <span>
              <FormattedMessage
                id="serviceRequestForm.globalError"
                defaultMessage="Lo sentimos, ha ocurrido un error inesperado. Por favor, vuelve a intentarlo"
                description="Mensaje de error que se muestra al usuario cuando no se ha podido crear la solicitud de presupuesto"
              />
            </span>
          </Alert>
        )}

        <FreeAndWithoutObligation />

        {showCommercialPhone && <CommercialPhone />}
      </>
    );
  };

  return (
    <div ref={formRef} className={styles['serviceRequestForm']}>
      {ShowComponentsHandler()}
    </div>
  );
}

export default ServiceRequestFormRenderer;
