import React from 'react';
import styles from './ProgressBar.module.scss';

type ProgressBarProps = {
  progress: number;
  className?: string;
};

function ProgressBar({ progress, className }: ProgressBarProps): React.ReactElement {
  const width = Math.round(progress * 100);
  return (
    <div className={className} data-testid="progressBar">
      <div className={styles.ProgressBar}>
        <div className={styles.ProgressBar__inner} style={{ width: `${width}%` }}></div>
      </div>
    </div>
  );
}

export default ProgressBar;
