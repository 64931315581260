import ApplicationConfiguration from '@src/core/ApplicationConfiguration';
import { eventBusSingleton } from '@src/core/infrastructure/Events/EventBus';
import AmplitudeServiceRequestFormEventListener from '@src/core/infrastructure/Events/Amplitude/AmplitudeServiceRequestFormEventListener';
import I18n from '@src/core/I18n';

class Application {
  constructor(private config: ApplicationConfiguration) {}

  initialize(): Application {
    this.configureI18n();
    this.configureAmplitudeListener();
    return this;
  }

  private configureI18n() {
    I18n.configureLocales(this.config.locale);
  }

  private configureAmplitudeListener() {
    if (!this.config.isUserTrackingEnabled) {
      console.warn('user tracking is disabled, skipping amplitude listeners');
      return;
    }

    new AmplitudeServiceRequestFormEventListener(
      this.config.amplitudeClient,
      this.config.country,
      this.config.formConfiguration
    ).register(eventBusSingleton);
  }
}

export default Application;
