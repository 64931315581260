import React, { FunctionComponent } from 'react';
import styles from './ButtonLabel.module.scss';

type ButtonLabelProps = {
  children: React.ReactNode;
  className?: string;
};
const ButtonLabel: FunctionComponent<ButtonLabelProps> = ({
  children,
  className,
}: ButtonLabelProps) => (
  <div className={`${className || ''} ${styles.buttonLabel}`}>{children}</div>
);

export default ButtonLabel;
