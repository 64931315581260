import React, { ChangeEvent, useEffect, useState } from 'react';
import { adaptValidator, notEmptyValidation } from '@src/ui/helpers/form/validations';
import ValidationError from '@src/ui/helpers/form/ValidationError';

import Category, { CategoryType } from '@src/core/domain/Categories/Category';
import CategoryGroupTree from '@src/core/domain/Categories/CategoryGroupTree';

import { AutocompleteOption } from '@src/ui/components/Input/AsyncAutocomplete';
import Input from '@src/ui/components/Input/Input';
import ButtonLabel from '@src/ui/components/ButtonLabel/ButtonLabel';
import JobTypeAutocomplete from './JobTypeAutocomplete';
import JobTypeAutocompleteOption from './JobTypeAutocompleteOption';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import styles from './JobTypeStep.module.scss';
import StepTitle from '@src/ui/apps/ServiceRequest/Steps/StepTitle';
import { eventBusSingleton } from '@src/core/infrastructure/Events/EventBus';
import {
  CategorySelected,
  JobTypeSelected,
  JobTypeStepRendered,
} from '@src/ui/apps/ServiceRequest/ServiceRequestEvents';
import { FormattedMessage } from 'react-intl';
import ButtonGroup from '../../ButtonGroup';
import NextButton from '../../NextButton';
import { ServiceRequestFormContext } from '../../ServiceRequestFormContext';
import { useForm } from 'react-hook-form';
import { useFormHelper } from '@src/ui/helpers/form/ReactHookFormHelper';
import formStyles from '@src/ui/apps/ServiceRequest/ServiceRequestForm.module.scss';

import LogRocket from 'logrocket';

type JobTypeStepData = {
  categoryName: string;
  jobTypeId: string;
  jobTypeName: string;
};

export type JobTypeStepProps = {
  categoriesTree: CategoryGroupTree;
};

function JobTypeStep({ categoriesTree }: JobTypeStepProps): React.ReactElement {
  const { formData, handleStepCompleted } = React.useContext(ServiceRequestFormContext);
  const EMPTY_CATEGORY = { id: '', name: '', children: [], type: CategoryType.CATEGORY };
  const form = useForm<JobTypeStepData>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });
  const helper = useFormHelper(form);
  const [selectedCategory, setSelectedCategory] = useState<Category>(EMPTY_CATEGORY);
  const [jobTypeAutocomplete, setJobTypeAutocomplete] = useState<JobTypeAutocompleteOption>();

  const fillFormWithDefaultData = (category: Category) => {
    const autocomplete = new JobTypeAutocompleteOption(
      category.id,
      category.name,
      CategoryType.CATEGORY,
      ''
    );
    setSelectedCategory(category);
    setJobTypeAutocomplete(autocomplete);
  };

  useEffect(() => {
    if (!categoriesTree.length()) return;
    if (formData?.categoryName) {
      const category = categoriesTree.findCategoryByName(formData.categoryName);
      fillFormWithDefaultData(category);
    } else if (formData?.jobTypeId) {
      const category = categoriesTree.findCategoryByJobTypeId(formData.jobTypeId);
      fillFormWithDefaultData(category);
    }
  }, [categoriesTree, formData]);

  useEffect(() => {
    eventBusSingleton.fireEvent(new JobTypeStepRendered());
    LogRocket.init('dgoapv/habitissimo-2030');
  }, []);

  useEffect(() => {
    if (selectedCategory !== EMPTY_CATEGORY) {
      eventBusSingleton.fireEvent(new CategorySelected(selectedCategory));
    }
  }, [selectedCategory]);

  const onSubmit = (data: JobTypeStepData) => {
    handleStepCompleted(data);
  };

  return (
    <form
      onSubmit={form.handleSubmit(handleStepCompleted)}
      data-testid="jobTypeForm"
      className={formStyles['serviceRequestForm__form']}
    >
      <StepTitle className="step-title" data-testid="step-title">
        {selectedCategory.name ? (
          <FormattedMessage
            id="serviceRequestForm.jobTypeStep.header.withCategory"
            defaultMessage="¿Cuál es el tipo de trabajo de {categoryName} que necesitas?"
            description="Título del paso para escoger el tipo de trabajo que contiene una categoría de trabajo preseleccionada"
            values={{ categoryName: selectedCategory.name }}
          />
        ) : (
          <FormattedMessage
            id="serviceRequestForm.jobTypeStep.header.generic"
            defaultMessage="¿Qué tipo de trabajo necesitas?"
            description="Título genérico del paso para escoger el tipo de trabajo"
          />
        )}
      </StepTitle>

      {helper.errorMessage('categoryName') && (
        <ValidationError>{helper.errorMessage('categoryName')}</ValidationError>
      )}

      {categoriesTree && (
        <JobTypeAutocomplete
          defaultSelected={jobTypeAutocomplete}
          categoriesTree={categoriesTree}
          onChange={(option?: AutocompleteOption) => {
            const jobTypeOption = option as JobTypeAutocompleteOption;

            if (!jobTypeOption) {
              setSelectedCategory(EMPTY_CATEGORY);
              return;
            }

            if (jobTypeOption.isCategory()) {
              const category = categoriesTree.findCategoryById(jobTypeOption.id);
              setSelectedCategory(category);
            } else {
              const category = categoriesTree.findCategoryByJobTypeId(jobTypeOption.id);
              const jobType = {
                id: jobTypeOption.id,
                name: jobTypeOption.name,
                children: [],
                type: CategoryType.JOB_TYPE,
              };
              eventBusSingleton.fireEvent(new JobTypeSelected(category, jobType));
              onSubmit({
                jobTypeId: jobTypeOption.id,
                jobTypeName: jobTypeOption.name,
                categoryName: jobTypeOption.categoryName,
              });
            }
          }}
        />
      )}

      <Input
        type="hidden"
        inputRef={form.register({ validate: adaptValidator(notEmptyValidation) })}
        name="categoryName"
        value={selectedCategory?.name}
        status={helper.inputStatus('categoryName')}
        data-testid="category"
      />

      <div className={styles.category__jobTypes} data-testid="jobTypes-container">
        {selectedCategory.children.length !== 0 && (
          <p className={styles.category__jobTypes__heading}>
            <FormattedMessage
              id="serviceRequestForm.jobTypeStep.jobTypeList.header"
              defaultMessage="Selecciona el tipo de trabajo que más se adapte"
              description="Título del paso de selección del tipo de trabajo cuando se selecciona una categoría y muestra el listado de tipos de trabajos"
            />
          </p>
        )}

        <section className={styles.category__jobTypes__content}>
          {helper.errorMessage('jobTypeId') && (
            <ValidationError>{helper.errorMessage('jobTypeId')}</ValidationError>
          )}

          {selectedCategory.children.map((jobType, index, children) => (
            <ButtonLabel key={jobType.id} className={styles.category__jobTypes__entry}>
              <label htmlFor={jobType.id}>
                <Input
                  inputRef={form.register({ validate: adaptValidator(notEmptyValidation) })}
                  type="radio"
                  id={jobType.id}
                  name="jobTypeId"
                  value={jobType.id}
                  defaultChecked={formData?.jobTypeId === jobType.id}
                  data-testid="jobType"
                  status={helper.inputStatus('jobTypeId')}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    eventBusSingleton.fireEvent(new JobTypeSelected(selectedCategory, jobType));
                    onSubmit(form.getValues() as JobTypeStepData);
                  }}
                />
                {jobType.name}
              </label>
            </ButtonLabel>
          ))}
        </section>
      </div>

      <ButtonGroup>
        <NextButton />
      </ButtonGroup>
    </form>
  );
}

export default JobTypeStep;
export type { JobTypeStepData };
