import React, { PropsWithChildren } from 'react';
import ValidationError from '@src/ui/helpers/form/ValidationError';
import styles from './FormField.module.scss';
import Label from '@src/ui/components/Label/Label';
import { useIntl } from 'react-intl';

type FormFieldProps = {
  labelText: string;
  errorMessage?: string | React.ReactElement;
  inputExample?: string;
  inputId?: string;
  'data-testid'?: string;
};

function FormField(props: PropsWithChildren<FormFieldProps>): React.ReactElement {
  const intl = useIntl();

  return (
    <div className={styles.formField}>
      <Label htmlFor={props.inputId}>{props.labelText}</Label>
      {props.errorMessage && <ValidationError>{props.errorMessage}</ValidationError>}
      {props.children}
      {props.inputExample && (
        <aside className={styles.formField__example}>
          {intl.formatMessage(
            {
              id: 'ui.components.formField.example',
              defaultMessage: 'Ejemplo: {inputExample}',
              description:
                'Este texto es el que se muestra justo antes del texto explicativo del campo, estilo "Ejemplo: (texto explicativo)"',
            },
            {
              inputExample: props.inputExample,
            }
          )}
        </aside>
      )}
    </div>
  );
}

export default FormField;
export type { FormFieldProps };
