import React from 'react';
import Button, { ButtonTypes } from '@src/ui/components/Button/Button';
import { FormattedMessage } from 'react-intl';

export type GoBackProps = {
  goBack: () => void;
  disabled?: boolean;
};

function BackButton({ goBack, disabled }: GoBackProps): React.ReactElement {
  return (
    <Button
      type="button"
      variant={ButtonTypes.SECONDARY}
      onClick={goBack}
      disabled={disabled}
      data-testid="backButton"
    >
      <FormattedMessage
        id="serviceRequestForm.navigationButtons.back"
        defaultMessage="Volver"
        description="Botón de navegación del formulario hacia atrás"
      />
    </Button>
  );
}

export default BackButton;
