import React, { ChangeEvent, FocusEvent, FunctionComponent } from 'react';
import InputStatus from '@src/ui/helpers/form/InputStatus';
import styles from './Input.module.scss';
import cx from 'classnames';
import { ReferenceableComponent } from '@src/ui/helpers/masks/withMask';

export type InputType = ReferenceableComponent & {
  name: string;
  id?: string;
  type?: string;
  value?: string;
  defaultValue?: string;
  status?: InputStatus;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (focusEvent: FocusEvent<HTMLInputElement>) => void;
  onKeyPress?: (keyPressEvent: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (keyPressEvent: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  placeholder?: string;
  defaultChecked?: boolean;
  'data-testid'?: string;
};

const isRadioInput = (type: string | undefined): boolean => type === 'radio';

const Input: FunctionComponent<InputType> = (props: InputType) => {
  const inputStatus = props.status;
  const className = cx(styles.input, styles[`input--${props.type}`], {
    [styles['input--error']]: !isRadioInput(props.type) && inputStatus === InputStatus.ERROR,
    [styles['input--success']]: !isRadioInput(props.type) && inputStatus === InputStatus.SUCCESS,
  });

  return (
    <input
      ref={props.inputRef}
      type={props.type}
      name={props.name}
      id={props.id}
      value={props.value}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onKeyPress={props.onKeyPress}
      onKeyUp={props.onKeyUp}
      className={className}
      disabled={props.disabled}
      placeholder={props.placeholder}
      defaultChecked={props.defaultChecked}
      data-testid={props['data-testid']}
    />
  );
};

Input.defaultProps = {
  type: 'text',
};

export default Input;
