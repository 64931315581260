import { EventBus } from '@src/core/infrastructure/Events/EventBus';
import {
  CategorySelected,
  ContactDataStepRendered,
  DescriptionStepRendered,
  FindingProfessionalsRendered,
  ImageUploadEnded,
  ImageUploadStarted,
  JobTypeSelected,
  JobTypeStepRendered,
  LocationStepRendered,
  LocationWithParentSubmitted,
  PostalCodeLocationSubmitted,
  QuestionsLoaded,
  QuestionStepRendered,
  SharePrivateServiceRequestClickedReject,
  SharePrivateServiceRequestClickedShare,
  SharePrivateServiceRequestStepRendered,
  ViewServiceRequestAboutToQuit,
  ViewServiceRequestCancelReason,
  RequestServiceRequestCancelReason,
} from '@src/ui/apps/ServiceRequest/ServiceRequestEvents';
import {
  ForwardServiceRequestClickedAccept,
  ForwardServiceRequestClickedViewDetails,
  ForwardServiceRequestClickedReject,
  ForwardServiceRequestRendered,
} from '@src/ui/apps/ForwardServiceRequest/ForwardServiceRequestEvents';
import Question from '@src/core/domain/Questions/Question';
import { FormConfiguration } from '@src/core/ApplicationConfiguration';
import { AmplitudeClient } from '../../AmplitudeQueuedClient';
import Tracking from '@src/ui/apps/ServiceRequest/hooks/useTracking';

class AmplitudeServiceRequestFormEventListener {
  private _client: AmplitudeClient;
  private _country: string;
  private _accumulatedData: Record<string, unknown>;
  private _questions: Question[];

  constructor(
    client: AmplitudeClient,
    country: string,
    formConfiguration: FormConfiguration,
    initialAccumulatedData: Record<string, string> = {}
  ) {
    this._client = client;
    this._country = country;
    this._accumulatedData = {
      form: formConfiguration.name,
      source_start_page: formConfiguration.sourceStartPage,
      source_end_page: formConfiguration.sourceEndPage,
      page_path: formConfiguration.pagePath,
      ...initialAccumulatedData,
    };
    this._questions = [];
  }

  register(dispatcher: EventBus): void {
    if (!this._client) {
      console.warn('Amplitude client not found, not registering amplitude event listeners');
      return;
    }

    this.initializeAmplitudeUserProperties();

    dispatcher.addListener(
      ForwardServiceRequestRendered.eventName,
      this.forwardServiceRequestRendered.bind(this)
    );
    dispatcher.addListener(
      ForwardServiceRequestClickedAccept.eventName,
      this.forwardServiceRequestClickedAccept.bind(this)
    );
    dispatcher.addListener(
      ForwardServiceRequestClickedReject.eventName,
      this.forwardServiceRequestClickedReject.bind(this)
    );
    dispatcher.addListener(
      ForwardServiceRequestClickedViewDetails.eventName,
      this.forwardServiceRequestClickedViewDetails.bind(this)
    );

    dispatcher.addListener(JobTypeStepRendered.eventName, this.categoryStepViewed.bind(this));
    dispatcher.addListener(CategorySelected.eventName, this.categorySelected.bind(this));
    dispatcher.addListener(JobTypeSelected.eventName, this.jobTypeSelected.bind(this));
    dispatcher.addListener(LocationStepRendered.eventName, this.locationStepViewed.bind(this));

    dispatcher.addListener(
      LocationWithParentSubmitted.eventName,
      this.locationWithParentSubmitted.bind(this)
    );
    dispatcher.addListener(
      FindingProfessionalsRendered.eventName,
      this.findingProfessionalsRendered.bind(this)
    );
    dispatcher.addListener(QuestionsLoaded.eventName, this.questionsLoaded.bind(this));
    dispatcher.addListener(QuestionStepRendered.eventName, this.questionStepViewed.bind(this));
    dispatcher.addListener(
      SharePrivateServiceRequestStepRendered.eventName,
      this.sharePrivateServiceRequestStepViewed.bind(this)
    );
    dispatcher.addListener(
      SharePrivateServiceRequestClickedShare.eventName,
      this.sharePrivateServiceRequestClickedShare.bind(this)
    );
    dispatcher.addListener(
      SharePrivateServiceRequestClickedReject.eventName,
      this.sharePrivateServiceRequestClickedReject.bind(this)
    );
    dispatcher.addListener(
      DescriptionStepRendered.eventName,
      this.descriptionStepViewed.bind(this)
    );
    dispatcher.addListener(ImageUploadStarted.eventName, this.imageUploadStarted.bind(this));
    dispatcher.addListener(ImageUploadEnded.eventName, this.imageUploadEnded.bind(this));
    dispatcher.addListener(
      ContactDataStepRendered.eventName,
      this.contactDataStepViewed.bind(this)
    );

    dispatcher.addListener(
      ViewServiceRequestAboutToQuit.eventName,
      this.closeFormAboutToQuitStepViewed.bind(this)
    );

    dispatcher.addListener(
      ViewServiceRequestCancelReason.eventName,
      this.closeFormCancelReasonStepViewed.bind(this)
    );

    dispatcher.addListener(
      RequestServiceRequestCancelReason.eventName,
      this.closeFormCancelReasonStepRequest.bind(this)
    );
  }

  private initializeAmplitudeUserProperties(): void {
    const properties = {
      country: this._country,
    };

    this._client.setUserProperties(properties);
  }

  private forwardServiceRequestRendered(event: ForwardServiceRequestRendered): void {
    this.logEventWithAcumulatedData('view step forward service request');
  }

  private forwardServiceRequestClickedAccept(event: ForwardServiceRequestClickedAccept): void {
    this.logEventWithAcumulatedData('click step forward service request', {
      selection: 'forward',
    });
  }

  private forwardServiceRequestClickedReject(event: ForwardServiceRequestClickedReject): void {
    this.logEventWithAcumulatedData('click step forward service request', {
      selection: 'reject',
    });
  }

  private forwardServiceRequestClickedViewDetails(
    event: ForwardServiceRequestClickedViewDetails
  ): void {
    this.logEventWithAcumulatedData('click step forward service request', {
      selection: 'details',
    });
  }

  private categoryStepViewed(event: JobTypeStepRendered): void {
    const accumulatedData = this._accumulatedData;
    this.logEventWithoutAcumulatedData('view step subcategory', {
      source_start_page: accumulatedData['source_start_page'],
      page_path: accumulatedData['page_path'],
    });
    Tracking('view_step_subcategory', {
      source_start_page: this._accumulatedData['source_start_page'],
      page_path: this._accumulatedData['page_path'],
    });
  }

  private categorySelected(event: CategorySelected): void {
    const accumulatedData = this._accumulatedData;
    this.logEventWithoutAcumulatedData('view step subsubcategory', {
      category: 'null',
      subcategory: event.category.name,
      subsubcategory: 'null',
      source_start_page: accumulatedData['source_start_page'],
      page_path: accumulatedData['page_path'],
    });
    Tracking('view_step_subsubcategory', {
      category: null,
      subcategory: event.category.name,
      subsubcategory: null,
      source_start_page: accumulatedData['source_start_page'],
      page_path: accumulatedData['page_path'],
    });
  }

  private jobTypeSelected(event: JobTypeSelected): void {
    this._accumulatedData['subcategory id'] = event.category.id;
    this._accumulatedData['subcategory label'] = event.category.name;
    this._accumulatedData['subsubcategory id'] = event.jobType.id;
    this._accumulatedData['subsubcategory label'] = event.jobType.name;
  }

  private locationStepViewed(event: LocationStepRendered): void {
    const accumulatedData = this._accumulatedData;
    this.logEventWithoutAcumulatedData('view step location', {
      category: 'null',
      subcategory: accumulatedData['subcategory label'],
      subsubcategory: accumulatedData['subsubcategory label'],
      source_start_page: accumulatedData['source_start_page'],
      page_path: accumulatedData['page_path'],
    });
    Tracking('view_step_location', {
      source_start_page: accumulatedData['source_start_page'],
      page_path: accumulatedData['page_path'],
      category: null,
      subcategory: accumulatedData['subcategory label'],
      subsubcategory: accumulatedData['subsubcategory label'],
    });
  }

  private getLastFiredEvent = () => {
    const formEvents = [
      'view_step_subcategory',
      'view_step_subsubcategory',
      'view_step_location',
      'view_step_dynamic_questions',
      'view_step_description',
      'view_step_contact_details_customer',
    ];

    const events = [...window.dataLayer];

    return events.reverse().find((lastEvent) => formEvents.includes(lastEvent.event));
  };
  private closeFormAboutToQuitStepViewed(): void {
    const accumulatedData = this._accumulatedData;
    const lastEvent = this.getLastFiredEvent();

    Tracking('view_service_request_form_about_to_quit', {
      form_step: lastEvent?.event,
      source_start_page: accumulatedData['source_start_page'],
      page_path: accumulatedData['page_path'],
      category: null,
      subcategory: accumulatedData['subcategory label'],
      subsubcategory: accumulatedData['subsubcategory label'],
      city: null,
      state: null,
      question_index: lastEvent?.question_index || null,
    });
  }

  private closeFormCancelReasonStepViewed(): void {
    const accumulatedData = this._accumulatedData;
    const lastEvent = this.getLastFiredEvent();

    Tracking('view_service_request_cancel_reason', {
      form_step: lastEvent?.event,
      source_start_page: accumulatedData['source_start_page'],
      page_path: accumulatedData['page_path'],
      category: null,
      subcategory: accumulatedData['subcategory label'],
      subsubcategory: accumulatedData['subsubcategory label'],
      city: null,
      state: null,
      question_index: lastEvent?.question_index || null,
    });
  }

  private closeFormCancelReasonStepRequest(event: RequestServiceRequestCancelReason): void {
    const accumulatedData = this._accumulatedData;
    const lastEvent = this.getLastFiredEvent();

    Tracking('service_request_form_cancelled', {
      form_step: lastEvent?.event,
      source_start_page: accumulatedData['source_start_page'],
      page_path: accumulatedData['page_path'],
      category: null,
      subcategory: accumulatedData['subcategory label'],
      subsubcategory: accumulatedData['subsubcategory label'],
      city: null,
      state: null,
      question_index: lastEvent?.question_index || null,
      reason: event.reason,
    });
  }

  // this event has been disabled
  private postalCodeLocationSubmitted(event: PostalCodeLocationSubmitted): void {
    this._accumulatedData['postal code'] = event.postalCode;
    delete this._accumulatedData['state id'];
    delete this._accumulatedData['state label'];
  }

  private locationWithParentSubmitted(event: LocationWithParentSubmitted): void {
    this._accumulatedData['state id'] = event.firstLevelLocation.id;
    this._accumulatedData['state label'] = event.firstLevelLocation.name;
    delete this._accumulatedData['postal code'];
  }

  private findingProfessionalsRendered(event: FindingProfessionalsRendered): void {
    this.logEventWithAcumulatedData('view loading search business', {});
  }

  private questionsLoaded(event: QuestionsLoaded): void {
    this._questions = event.questions;
  }

  private questionStepViewed(event: QuestionStepRendered): void {
    const accumulatedData = this._accumulatedData;
    this.logEventWithoutAcumulatedData('view step dynamic questions', {
      source_start_page: accumulatedData['source_start_page'],
      page_path: accumulatedData['page_path'],
      category: 'null',
      subcategory: accumulatedData['subcategory label'],
      subsubcategory: accumulatedData['subsubcategory label'],
      max_questions: this._questions.length,
      question_index: this.positionOfQuestionStepForQuestionId(event.question.id),
      question_label: event.question.question,
      question_id: event.question.id,
      city: 'null',
      state: 'null',
    });
    Tracking('view_step_dynamic_questions', {
      source_start_page: accumulatedData['source_start_page'],
      page_path: accumulatedData['page_path'],
      category: null,
      subcategory: accumulatedData['subcategory label'],
      subsubcategory: accumulatedData['subsubcategory label'],
      max_questions: this._questions.length,
      question_index: this.positionOfQuestionStepForQuestionId(event.question.id),
      question_label: event.question.question,
      question_id: event.question.id,
      city: null,
      state: null,
    });
  }

  private sharePrivateServiceRequestStepViewed(
    event: SharePrivateServiceRequestStepRendered
  ): void {
    this.logEventWithAcumulatedData('view step is private');
  }

  private sharePrivateServiceRequestClickedShare(
    event: SharePrivateServiceRequestClickedShare
  ): void {
    this.logEventWithAcumulatedData('click step is private', {
      selection: 'accept',
    });
  }

  private sharePrivateServiceRequestClickedReject(
    event: SharePrivateServiceRequestClickedReject
  ): void {
    this.logEventWithAcumulatedData('click step is private', {
      selection: 'reject',
    });
  }

  private descriptionStepViewed(event: DescriptionStepRendered): void {
    const accumulatedData = this._accumulatedData;

    this.logEventWithoutAcumulatedData('view step description', {
      source_start_page: accumulatedData['source_start_page'],
      page_path: accumulatedData['page_path'],
      category: 'null',
      subcategory: accumulatedData['subcategory label'],
      subsubcategory: accumulatedData['subsubcategory label'],
      city: 'null',
      state: 'null',
    });
    Tracking('view_step_description', {
      source_start_page: accumulatedData['source_start_page'],
      page_path: accumulatedData['page_path'],
      category: null,
      subcategory: accumulatedData['subcategory label'],
      subsubcategory: accumulatedData['subsubcategory label'],
      city: null,
      state: null,
    });
  }

  private imageUploadStarted(event: ImageUploadStarted): void {
    this.logEvent('upload image started', {
      'file size': event.fileSizeInBytes,
    });
  }

  private imageUploadEnded(event: ImageUploadEnded): void {
    this.logEvent('upload image ended', {
      'file size': event.fileSizeInBytes,
    });
  }

  private contactDataStepViewed(event: ContactDataStepRendered): void {
    const accumulatedData = this._accumulatedData;
    this.logEventWithoutAcumulatedData('view step contact details customer', {
      source_start_page: accumulatedData['source_start_page'],
      page_path: accumulatedData['page_path'],
      category: 'null',
      subcategory: accumulatedData['subcategory label'],
      subsubcategory: accumulatedData['subsubcategory label'],
      city: 'null',
      state: 'null',
    });
    Tracking('view_step_contact_details_customer', {
      source_start_page: accumulatedData['source_start_page'],
      page_path: accumulatedData['page_path'],
      category: null,
      subcategory: accumulatedData['subcategory label'],
      subsubcategory: accumulatedData['subsubcategory label'],
      city: null,
      state: null,
    });
  }

  private logEventWithAcumulatedData(name: string, data: Record<string, unknown> = {}): void {
    this.logEvent(name, {
      ...this._accumulatedData,
      ...data,
    });
  }

  private logEventWithoutAcumulatedData(name: string, data: Record<string, unknown> = {}): void {
    this.logEvent(name, {
      ...data,
    });
  }

  private logEvent(name: string, data: Record<string, unknown>) {
    this._client.logEvent(name, data);
  }

  private positionOfQuestionStepForQuestionId(questionId: string): number {
    return this._questions.findIndex((question) => question.id === questionId) + 1;
  }
}

export default AmplitudeServiceRequestFormEventListener;
