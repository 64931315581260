import React, { FunctionComponent } from 'react';
import styles from './Link.module.scss';

type LinkProps = {
  children: React.ReactNode;
  href?: string;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => void)
    | undefined;
  'data-testid'?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
};

const Link: FunctionComponent<LinkProps> = (props: LinkProps) => {
  if (props.href) {
    return (
      <a
        href={props.href}
        onClick={props.onClick}
        data-testid={props['data-testid']}
        className={styles.link}
        target={props.target}
      >
        {props.children}
      </a>
    );
  }

  return (
    <button
      type="button"
      onClick={props.onClick}
      className={styles['link--button']}
      data-testid={props['data-testid']}
    >
      {props.children}
    </button>
  );
};

export default Link;
