import LocationIdentifier from '@src/core/domain/Locations/LocationIdentifier';
import HttpClient from '@src/core/infrastructure/http';

type ProfessionalsAvailabilityResponse = {
  redirectUrl: string;
};

class CheckProfessionalsWithAutomaticPurchases {
  private readonly baseUrl: string;
  private httpClient: HttpClient;

  constructor(baseUrl: string, httpClient: HttpClient) {
    this.baseUrl = baseUrl;
    this.httpClient = httpClient;
  }

  async execute(location: LocationIdentifier, jobTypeId: string): Promise<URL | null> {
    let apiUrl = `${this.baseUrl}/professionals/availability/jobType/${jobTypeId}`;

    if (location.locationId) {
      apiUrl = `${apiUrl}/location/${location.locationId}`;
    } else if (location.postalCode) {
      apiUrl = `${apiUrl}/postalCode/${location.postalCode}`;
    }

    const response = await this.httpClient.get<ProfessionalsAvailabilityResponse>(apiUrl);
    const urlString: string = response.data().redirectUrl;
    if (urlString) {
      return new URL(urlString);
    }
    return null;
  }
}

export default CheckProfessionalsWithAutomaticPurchases;
