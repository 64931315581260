import React, { useState } from 'react';
import ForwardServiceRequestRenderer from '@src/ui/apps/ForwardServiceRequest/ForwardServiceRequestRenderer';
import { ServiceRequestFormProps } from '@src/ui/apps/ServiceRequest/serviceRequestFormReducer';
import ForwardServiceRequestToBusinessFactory from '@src/core/useCases/ServiceRequest/ForwardServiceRequestToBusinessFactory';
import { eventBusSingleton } from '@src/core/infrastructure/Events/EventBus';
import { BrowserStorage } from '@src/core/domain/storage/BrowserStorage';
import { ServiceRequestCreated } from '@src/ui/apps/ServiceRequest/ServiceRequestEvents';
import LocationIdentifier from '@src/core/domain/Locations/LocationIdentifier';
import HttpError from '../../../core/infrastructure/http/HttpError';
import I18n from '@src/core/I18n';
import { isDirectServiceRequest } from '@src/core/domain/ServiceRequest/ServiceRequest';
import {
  ForwardServiceRequestClickedAccept,
  ForwardServiceRequestClickedReject,
  ForwardServiceRequestError,
  ForwardServiceRequestSuccess,
} from '@src/ui/apps/ForwardServiceRequest/ForwardServiceRequestEvents';

type PreviousServiceRequestData = {
  locationIdentifier: LocationIdentifier;
  jobTypeName: string;
  id: string;
  url: string;
};

enum ForwardServiceRequestStatus {
  IS_VISIBLE = 'isVisible',
  FORWARD_REQUESTED = 'forwardRequested',
  FORWARD_SUCCEED = 'forwardSucceed',
  FORWARD_REJECTED = 'forwardRejected',
  ERROR = 'error',
}

const STORAGE_KEY = 'previousServiceRequestData';

function serializeToStorage(event: ServiceRequestCreated): string {
  return JSON.stringify({
    id: event.createServiceRequestSuccess.id,
    url: event.createServiceRequestSuccess.url,
    jobTypeName: event.serviceRequest.jobTypeData.name,
    locationIdentifier: event.serviceRequest.locationIdentifier,
  });
}

function unserializeFromStorage(cookieData: string | null): PreviousServiceRequestData | null {
  if (!cookieData) {
    return null;
  }

  return JSON.parse(cookieData) as PreviousServiceRequestData;
}

function emptyCookieIfNoTradesLeftToAdd(
  tradesLeftToAddServiceRequest: number,
  storage: BrowserStorage
) {
  if (tradesLeftToAddServiceRequest === 0) {
    storage.removeItem(STORAGE_KEY);
  }
}

function withForwardServiceRequest(
  WrappedRenderer: React.ComponentType<ServiceRequestFormProps>,
  storage: BrowserStorage
): React.ComponentType<ServiceRequestFormProps> {
  eventBusSingleton.addListener(ServiceRequestCreated.eventName, (event: ServiceRequestCreated) => {
    if (isDirectServiceRequest(event.serviceRequest)) {
      storage.setItem(STORAGE_KEY, serializeToStorage(event));
    }
  });

  const RendererWithForwardServiceRequest = (props: ServiceRequestFormProps) => {
    const previousServiceRequestData = unserializeFromStorage(storage.getItem(STORAGE_KEY));

    const [status, setStatus] = useState(ForwardServiceRequestStatus.IS_VISIBLE);
    const [errorMessage, setErrorMessage] = useState('');

    const businessId = props.configuration.businessId;
    const businessName = props.configuration.businessName ?? '';
    const intl = I18n.intl();

    function handleErrorMessage(httpError: HttpError) {
      let errorMessage = '';
      if (httpError.code() === 'FULL_SERVICE_REQUEST') {
        errorMessage = intl.formatMessage({
          id: 'forwardServiceRequest.errorMessage.fullServiceRequest',
          defaultMessage: 'Se ha llegado al límite de profesionales apuntados al presupuesto',
          description:
            'Mensaje de error cuando intentamos invitar a un profesional a un presupuesto existente y se ha llegado al límite de profesionales apuntados al presupuesto',
        });
      } else if (httpError.code() === 'INVALID_SERVICE_REQUEST_ID') {
        errorMessage = intl.formatMessage({
          id: 'forwardServiceRequest.errorMessage.invalidServiceRequestId',
          defaultMessage: 'No se ha podido recuperar tu presupuesto, pide uno nuevo',
          description:
            'Mensaje de error cuando intentamos invitar a un profesional a un presupuesto existente y el presupuesto ya no se puede recuperar',
        });
      }
      setErrorMessage(errorMessage);
    }

    function handleKnownErrors(httpError: HttpError, storage: BrowserStorage) {
      if (
        httpError.code() === 'FULL_SERVICE_REQUEST' ||
        httpError.code() === 'INVALID_SERVICE_REQUEST_ID'
      ) {
        handleErrorMessage(httpError);
        storage.removeItem(STORAGE_KEY);
      } else {
        eventBusSingleton.fireEvent(new ForwardServiceRequestError(httpError.code()));
      }
    }

    if (
      previousServiceRequestData &&
      businessId &&
      status !== ForwardServiceRequestStatus.FORWARD_REJECTED
    ) {
      return (
        <ForwardServiceRequestRenderer
          businessName={businessName}
          jobTypeName={previousServiceRequestData.jobTypeName}
          serviceRequestUrl={previousServiceRequestData.url}
          onForwardReject={() => {
            setStatus(ForwardServiceRequestStatus.FORWARD_REJECTED);
            eventBusSingleton.fireEvent(new ForwardServiceRequestClickedReject());
          }}
          onForwardAccept={() => {
            setStatus(ForwardServiceRequestStatus.FORWARD_REQUESTED);
            eventBusSingleton.fireEvent(new ForwardServiceRequestClickedAccept());
            ForwardServiceRequestToBusinessFactory.create()
              .execute(businessId, previousServiceRequestData.id)
              .then((tradesLeftToAddServiceRequest) => {
                setStatus(ForwardServiceRequestStatus.FORWARD_SUCCEED);
                emptyCookieIfNoTradesLeftToAdd(tradesLeftToAddServiceRequest, storage);
                eventBusSingleton.fireEvent(
                  new ForwardServiceRequestSuccess(businessName, previousServiceRequestData.url)
                );
              })
              .catch((httpError: HttpError) => {
                setStatus(ForwardServiceRequestStatus.ERROR);
                handleKnownErrors(httpError, storage);
              });
          }}
          errorMessage={errorMessage}
          status={status}
        />
      );
    }
    return <WrappedRenderer {...props} />;
  };
  RendererWithForwardServiceRequest.displayName = 'RendererWithForwardServiceRequest';

  return RendererWithForwardServiceRequest;
}

export default withForwardServiceRequest;
export { ForwardServiceRequestStatus };
