import React, { FunctionComponent } from 'react';
import styles from './Button.module.scss';
import cx from 'classnames';

export enum ButtonTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

type Props = {
  variant?: ButtonTypes;
  isLoading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit';
  children?: React.ReactNode;
  className?: string;
  onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
  icon?: string;
};

type ButtonType = Props;

const Button: FunctionComponent<ButtonType> = (props: ButtonType) => {
  const { variant, isLoading, children, className, disabled, icon, ...restProps } = props;

  const buttonVariantClass = `button--${props.variant}`;
  const componetClassName = cx(styles.button, styles[buttonVariantClass], className, {
    [styles['button--is-loading']]: isLoading,
    button: true,
  });

  return (
    <button className={componetClassName} disabled={disabled || isLoading} {...restProps}>
      {icon && <i className={`icon icon-${icon} ${styles.button__icon}`}></i>}
      {children}
    </button>
  );
};

Button.defaultProps = {
  variant: ButtonTypes.PRIMARY,
  type: 'button',
};

export default Button;
