enum CategoryType {
  CATEGORY_GROUP = 'CATEGORY_GROUP',
  CATEGORY = 'CATEGORY',
  JOB_TYPE = 'JOB_TYPE',
}

type Category = {
  readonly id: string;
  readonly name: string;
  readonly children: Category[];
  readonly type: CategoryType;
};

export default Category;
export { CategoryType };
