import Alert, { AlertTypes } from '@src/ui/components/Alert/Alert';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  PhotoUploadState,
  emptyPhotoUploadState,
} from '@src/ui/apps/ServiceRequest/hooks/usePhotoUpload';
import React, { useEffect, useRef } from 'react';

import { DescriptionStepRendered } from '@src/ui/apps/ServiceRequest/ServiceRequestEvents';
import Icon from '@src/ui/components/Icon/Icon';
import PhotoUpload from '@src/ui/components/PhotoUpload/PhotoUpload';
import { ServiceRequestFormContext } from '@src/ui/apps/ServiceRequest/ServiceRequestFormContext';
import StepTitle from '@src/ui/apps/ServiceRequest/Steps/StepTitle';
import Textarea from '@src/ui/components/Input/Textarea';
import TooLargePhotoError from '@src/core/domain/Photos/TooLargePhotoError';
import UploadPhotoError from '@src/core/domain/Photos/UploadPhotoError';
import UploadedPhoto from '@src/core/domain/Photos/UploadedPhoto';
import classNames from 'classnames';
import { eventBusSingleton } from '@src/core/infrastructure/Events/EventBus';
import styles from './DescriptionStep.module.scss';
import { useForm } from 'react-hook-form';

const MAX_ALLOWED_PHOTOS_UPLOADED = 14;

type DescriptionStepData = {
  description: string;
};

export type DescriptionStepProps = {
  formControls?: React.ReactChild;
  onFilesAdded: (uploadedPhotos: File[]) => void;
  removeFile: (photoToDelete: UploadedPhoto) => void;
  uploads?: PhotoUploadState;
};

function DescriptionStep({
  formControls,
  onFilesAdded,
  removeFile,
  uploads = emptyPhotoUploadState,
}: DescriptionStepProps): React.ReactElement {
  const form = useForm<DescriptionStepData>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });
  const intl = useIntl();
  const { formData, handleStepCompleted } = React.useContext(ServiceRequestFormContext);
  const ref = useRef<HTMLElement>();

  useEffect(() => {
    eventBusSingleton.fireEvent(new DescriptionStepRendered());
  }, []);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <>
      <StepTitle className="step-title" data-testid="description-title">
        <FormattedMessage
          id="serviceRequestForm.descriptionStep.title"
          defaultMessage="¿Algo más que añadir?"
          description="Título del campo para añadir una descripción"
        />
      </StepTitle>
      <form
        data-testid="description-step"
        onSubmit={form.handleSubmit(handleStepCompleted)}
        className={styles.descriptionStep}
      >
        <p data-testid="description-optional" className={styles.descriptionStep__optional}>
          <FormattedMessage
            id="serviceRequestForm.descriptionStep.optionalLabel"
            defaultMessage="Opcional"
            description="Texto que indica que el campo es opcional"
          />
        </p>
        <Textarea
          name="description"
          inputRef={(r) => {
            form.register(r);
            ref.current = r;
          }}
          placeholder={intl.formatMessage({
            id: 'serviceRequestForm.descriptionStep.placeholder',
            defaultMessage:
              'Si lo deseas, puedes dar más detalles del trabajo que necesitas para que los profesionales sean más precisos a la hora de pasarte presupuesto. No incluyas ningún dato de contacto.',
            description: 'Placeholder para el campo de descripción',
          })}
          data-testid="description"
          defaultValue={formData.description}
        />
        {uploads.errors.length > 0 && (
          <Alert variant={AlertTypes.ERROR}>
            {uploads.errors.map((photoError, index) => (
              <div data-testid="photoUploadError" key={index}>
                {photoError instanceof TooLargePhotoError && (
                  <FormattedMessage
                    id="serviceRequestForm.descriptionStep.uploadImage.errors.fileTooLarge"
                    defaultMessage="El tamaño de {fileName} supera el máximo permitido de 8 megas"
                    description="Mensaje de error que aparece cuando el usuario intenta subir una imagen demasiado grande"
                    values={{ fileName: photoError.fileName }}
                  />
                )}
                {photoError instanceof UploadPhotoError && (
                  <FormattedMessage
                    id="serviceRequestForm.descriptionStep.uploadImage.errors.uploadError"
                    defaultMessage="Ha habido un error subiendo {fileName}"
                    description="Mensaje de error genérico que aparece cuando ha ocurrido algún tipo de error mientras el usuario intentaba subir una imagen"
                    values={{ fileName: photoError.fileName }}
                  />
                )}
              </div>
            ))}
          </Alert>
        )}
        <PhotoThumbnails
          uploadedPhotos={uploads.completed}
          uploadingPhotos={uploads.uploading}
          onRemoveCallback={removeFile}
        />
        <PhotoUpload
          onDropAccepted={onFilesAdded}
          disabled={uploads.completed.length >= MAX_ALLOWED_PHOTOS_UPLOADED}
        />
        {formControls}
      </form>
    </>
  );
}

type PhotoThumbnailsProps = {
  uploadedPhotos: UploadedPhoto[];
  uploadingPhotos: UploadedPhoto[];
  onRemoveCallback: (photo: UploadedPhoto) => void;
};

function PhotoThumbnails({
  uploadedPhotos,
  uploadingPhotos,
  onRemoveCallback,
}: PhotoThumbnailsProps): React.ReactElement | null {
  return (
    <ul className={styles.thumbnails}>
      {uploadedPhotos.map((image: UploadedPhoto, index) => (
        <li key={`${index}-${image.id}`} className={styles.thumbnails__item}>
          <PhotoThumbnail data-testid={`photoThumbnail-${index}`} image={image} />
          <button
            type="button"
            className={styles.thumbnails__remove}
            onClick={() => onRemoveCallback(image)}
            data-testid="removeThumbnailIcon"
          >
            <Icon name="close-o" />
          </button>
        </li>
      ))}
      {uploadingPhotos.map((image: UploadedPhoto, index) => (
        <li
          key={`tmp-${index}-${image.id}`}
          className={classNames(styles['thumbnails__item'], styles['thumbnails__item--tmp'])}
        >
          <PhotoThumbnail data-testid={`tmpPhotoThumbnail-${index}`} image={image} />
          <Icon name="refresh-o" className={styles.thumbnails__spinner} />
        </li>
      ))}
    </ul>
  );
}

PhotoThumbnails.defaultProps = {
  uploadingPhotos: [],
};

type PhotoThumbnailProps = {
  image: UploadedPhoto;
  'data-testid': string;
};

function PhotoThumbnail(props: PhotoThumbnailProps): React.ReactElement {
  return (
    <img
      data-testid={props['data-testid']}
      alt="thumbnail"
      src={props.image.thumbnailUrl || 'https://placehold.it/400x400'}
      className={styles.thumbnails__image}
    />
  );
}

export default DescriptionStep;
export { PhotoThumbnails, PhotoThumbnail, MAX_ALLOWED_PHOTOS_UPLOADED };
export type { DescriptionStepData };
