import CategoryReader from './CategoryReader';
import HttpClient from '../http';
import CategoryNotFoundError from '@src/core/domain/Categories/CategoryNotFoundError';

class ApiCategoryReader implements CategoryReader {
  private readonly baseUrl: string;
  private httpClient: HttpClient;

  constructor(baseUrl: string, httpClient: HttpClient) {
    this.baseUrl = baseUrl;
    this.httpClient = httpClient;
  }

  async readAll(): Promise<string> {
    const response = await this.httpClient.get(`${this.baseUrl}/categories`);

    return JSON.stringify(response.data());
  }

  async readSearch(query: string): Promise<string> {
    const response = await this.httpClient.get(`${this.baseUrl}/categories/searchByText/${query}`);

    return JSON.stringify(response.data());
  }

  async fetchBySlug(slug: string): Promise<string> {
    try {
      const response = await this.httpClient.get(`${this.baseUrl}/categories/searchBySlug/${slug}`);
      return JSON.stringify(response.data());
    } catch (err) {
      if (err.isNotFoundError()) {
        throw new CategoryNotFoundError(`No category found for slug ${slug}`);
      }

      throw err;
    }
  }
}

export default ApiCategoryReader;
