import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import LabelledInput, { LabelledInputProps } from '@src/ui/components/LabelledInput/LabelledInput';
import {
  adaptValidator,
  combine,
  notEmptyValidation,
  postalCodeValidation,
} from '@src/ui/helpers/form/validations';
import { Controller, UseFormMethods } from 'react-hook-form';
import { useFormHelper } from '@src/ui/helpers/form/ReactHookFormHelper';
import { MaskedInputProps, withMask } from '@src/ui/helpers/masks/withMask';

export type PostalCodeData = {
  postalCode?: string;
};

const MaskedInput = withMask<LabelledInputProps>(LabelledInput);

export interface PostalCodeFieldProps<T extends PostalCodeData> extends MaskedInputProps {
  form: UseFormMethods<PostalCodeData>;
  formData?: T;
}

function PostalCodeField<T extends PostalCodeData>({
  form,
  formData,
  mask,
}: PostalCodeFieldProps<T>): React.ReactElement {
  const intl = useIntl();
  const helper = useFormHelper(form);
  const ref = useRef<HTMLElement>();

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <Controller
      name="postalCode"
      control={form.control}
      rules={{ validate: adaptValidator(combine(notEmptyValidation, postalCodeValidation)) }}
      defaultValue={formData?.postalCode || ''}
      onFocus={() => ref.current?.focus()}
      render={({ onBlur, onChange, value }) => (
        <MaskedInput
          inputRef={(r) => (ref.current = r)}
          onAccept={(newValue: string) => {
            onChange(newValue);
          }}
          onComplete={async (newValue) => {
            await onChange(newValue);
            onBlur();
          }}
          value={value}
          inputOnBlur={onBlur}
          mask={mask}
          inputType="tel"
          labelText={intl.formatMessage({
            id: 'serviceRequestForm.locationStep.postalCode.placeholder',
            defaultMessage: 'Código postal',
            description:
              'Placeholder que se muestra en el campo de código postal cuando aún no se ha escrito nada',
          })}
          inputName="postalCode"
          inputId="postalCode"
          inputStatus={helper.inputStatus('postalCode')}
          errorMessage={helper.errorMessage('postalCode')}
          data-testid="postalCode"
        />
      )}
    />
  );
}

export default PostalCodeField;
