import React from 'react';
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';
import Button, { ButtonTypes } from '@src/ui/components/Button/Button';
import styles from './PhotoUpload.module.scss';
import { FormattedMessage } from 'react-intl';

type PhotoUploadProps = {
  disabled?: boolean;
  onDropAccepted<T extends File>(files: T[], event: DropEvent): void;
  onDropRejected?(fileRejections: FileRejection[], event: DropEvent): void;
};

function PhotoUpload(props: PhotoUploadProps): React.ReactElement {
  const ACCEPTED_IMAGE_FORMATS = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp'];
  return (
    <Dropzone
      disabled={props.disabled}
      onDropAccepted={props.onDropAccepted}
      onDropRejected={props.onDropRejected}
      accept={ACCEPTED_IMAGE_FORMATS}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()} data-testid="photoUpload" className={styles.photoUpload}>
          <input {...getInputProps()} />
          <Button
            variant={ButtonTypes.SECONDARY}
            icon="camera-o"
            disabled={props.disabled}
            data-testid="photoUploadButton"
          >
            <FormattedMessage
              id="ui.components.photoUpload.buttonText"
              defaultMessage="Adjuntar fotografías <small>(si quieres)</small>"
              description="Botón que al hacer click permite subir fotos"
              values={{
                small: function small(text: string) {
                  return <span className={styles.photoUpload__optionalText}>{text}</span>;
                },
              }}
            />
          </Button>
        </div>
      )}
    </Dropzone>
  );
}

PhotoUpload.defaultProps = {
  disabled: false,
};

export default PhotoUpload;
