import { CategoryType } from '@src/core/domain/Categories/Category';
import { AutocompleteOption } from '@src/ui/components/Input/AsyncAutocomplete';
import { Highlighter } from 'react-bootstrap-typeahead';
import React from 'react';
import styles from './JobTypeAutocompleteOption.module.scss';

class JobTypeAutocompleteOption implements AutocompleteOption {
  readonly id: string;
  readonly name: string;
  readonly type: CategoryType;
  readonly categoryName: string;

  constructor(id: string, name: string, type: CategoryType, categoryName: string) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.categoryName = categoryName;
  }

  label(search = ''): React.ReactElement {
    const categoryLabel = <Highlighter search={search}>{this.name}</Highlighter>;
    if (this.isCategory()) {
      return categoryLabel;
    }

    return (
      <>
        {categoryLabel}{' '}
        <small className={styles.jobTypeAutocomplete__category}>
          em <Highlighter search={search}>{this.categoryName}</Highlighter>
        </small>
      </>
    );
  }

  labelKey(): string {
    return this.name;
  }

  value(): string {
    return this.id;
  }

  isCategory(): boolean {
    return this.type === CategoryType.CATEGORY;
  }
}

export default JobTypeAutocompleteOption;
