import {
  LocationStepRendered,
  LocationWithParentSubmitted,
  PostalCodeLocationSubmitted,
} from '@src/ui/apps/ServiceRequest/ServiceRequestEvents';
import React, { useEffect, useState } from 'react';

import { FormData } from '@src/ui/apps/ServiceRequest/useServiceRequestForm';
import { FormattedMessage } from 'react-intl';
import { LocationSearchAutocompleteData } from '@src/ui/apps/ServiceRequest/Steps/LocationStep/LocationSearchAutocomplete';
import { LocationSelectInputData } from './LocationSelectInput';
import { PostalCodeData } from './PostalCodeField';
import { ServiceRequestFormContext } from '../../ServiceRequestFormContext';
import StepTitle from '@src/ui/apps/ServiceRequest/Steps/StepTitle';
import { UseFormMethods } from 'react-hook-form/dist/types';
import { eventBusSingleton } from '@src/core/infrastructure/Events/EventBus';
import { getLocalizedService } from '@src/ui/helpers/localization';
import styles from '@src/ui/apps/ServiceRequest/Steps/LocationStep/LocationStep.module.scss';
import { useForm } from 'react-hook-form';

export type LocationStepData = PostalCodeData &
  LocationSelectInputData &
  LocationSearchAutocompleteData;
export type LocationStepRenderProps = {
  setEvent?: (event: unknown) => void;
  form: UseFormMethods<LocationStepData>;
  formData: Partial<FormData>;
};

export type LocationStepProps = {
  formControls?: React.ReactElement;
  changeJobTypeLink?: React.ReactNode;
};

function LocationStep({ changeJobTypeLink, formControls }: LocationStepProps): React.ReactElement {
  const { formData, handleStepCompleted } = React.useContext(ServiceRequestFormContext);
  const form = useForm<LocationStepData>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });
  const LocationStepRenderer = getLocalizedService<
    React.FunctionComponent<LocationStepRenderProps>
  >('LocationStepRenderer');
  const [event, setEvent] = useState<unknown>(undefined);
  useEffect(() => {
    eventBusSingleton.fireEvent(new LocationStepRendered());
  }, []);

  const wrappedOnSubmit = (locationStepData: LocationStepData) => {
    if (locationStepData['postalCode']) {
      eventBusSingleton.fireEvent(new PostalCodeLocationSubmitted(locationStepData['postalCode']));
    }

    if (event) {
      eventBusSingleton.fireEvent(event as LocationWithParentSubmitted);
    }

    handleStepCompleted(locationStepData);
  };

  return (
    <>
      <StepTitle className="step-title" data-testid="step-title">
        <FormattedMessage
          id="serviceRequestForm.locationStep.header"
          defaultMessage="¿Para qué zona necesitas los presupuestos de {categoryName}?"
          description="Título del paso para escoger la localidad donde realizar el tipo de trabajo seleccionado"
          values={{ categoryName: formData.categoryName }}
        />
      </StepTitle>
      {changeJobTypeLink}
      <form onSubmit={form.handleSubmit(wrappedOnSubmit)} className={styles.LocationStep}>
        <LocationStepRenderer form={form} formData={formData} setEvent={setEvent} />
        {formControls}
      </form>
    </>
  );
}

export default LocationStep;
