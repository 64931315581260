import React, { FunctionComponent } from 'react';
import styles from './Label.module.scss';

type LabelType = {
  children: React.ReactNode;
  htmlFor?: string;
};

const Label: FunctionComponent<LabelType> = (props: LabelType) => {
  return (
    <label className={styles.label} {...props}>
      {props.children}
    </label>
  );
};

export default Label;
