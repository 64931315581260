import CustomerContactData from '../CustomerContactData/CustomerContactData';
import { Answer } from '../Answer';
import LocationIdentifier from '../Locations/LocationIdentifier';
import ServiceRequestAdditionalData from './ServiceRequestAdditionalData';
import { JobTypeData } from '../Categories/JobTypeData';

export type ServiceRequestVisibility = {
  readonly businessId: number;
  readonly visibleToOtherBusinesses: boolean;
};

function isDirectServiceRequest(serviceRequest: ServiceRequest): number | undefined {
  return serviceRequest.visibility?.businessId;
}

type ServiceRequest = {
  readonly jobTypeData: JobTypeData;
  readonly locationIdentifier: LocationIdentifier;
  readonly answers: Answer[];
  readonly description: string;
  readonly photoIds: string[];
  readonly customerContactData: CustomerContactData;
  readonly termsAndConditions: boolean;
  readonly thirdParty: boolean;
  readonly visibility?: ServiceRequestVisibility;
  additionalData?: ServiceRequestAdditionalData;
  readonly clientId?: string;
};

export default ServiceRequest;
export { isDirectServiceRequest };
