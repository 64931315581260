import React, { ChangeEvent, useEffect, useState } from 'react';

import Button, { ButtonTypes } from '@src/ui/components/Button/Button';
import StepTitle from '@src/ui/apps/ServiceRequest/Steps/StepTitle';
import { FormattedMessage } from 'react-intl';
import styles from '../styles.module.scss';
import ButtonLabel from '@src/ui/components/ButtonLabel/ButtonLabel';
import Input from '@src/ui/components/Input/Input';
import {
  ViewServiceRequestCancelReason,
  RequestServiceRequestCancelReason,
} from '../../ServiceRequestEvents';
import { eventBusSingleton } from '@src/core/infrastructure/Events/EventBus';

const questions = [
  {
    value: '1',
    label: 'Não encontrei o serviço que eu precisava',
  },
  {
    value: '2',
    label: 'Não tenho as informações solicitadas',
  },
  {
    value: '3',
    label: 'Não quero colocar informações pessoais',
  },
  {
    value: '4',
    label: 'Não quero que os profissionais entrem em contato ',
  },
  {
    value: '5',
    label: 'Eu estava apenas pesquisando',
  },
  {
    value: '6',
    label: 'Outro',
  },
];

export function QuestionsStep(): React.ReactElement {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [optionTextOther, setOptionTextOther] = useState<string>('');

  useEffect(() => {
    eventBusSingleton.fireEvent(new ViewServiceRequestCancelReason());
  }, []);

  const exitStepsToCloseForm = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.rfqModal?.close(true);
  };

  const handleSubmit = () => {
    const selectedQuestion = questions.find((q) => q.value === selectedOption);
    let reason = selectedQuestion?.label || '';

    if (selectedOption === '6') {
      reason = reason + ' - ' + optionTextOther;
    }

    eventBusSingleton.fireEvent(new RequestServiceRequestCancelReason(reason));
    exitStepsToCloseForm();
  };

  return (
    <section data-testid="QuestionsStep" className={styles['container']}>
      <div>
        <StepTitle className="step-title" data-testid="description-title">
          <FormattedMessage
            id="serviceRequestForm.QuestionsStep.title"
            defaultMessage="Esperamos te ver novamente!"
            description="Esperamos te ver novamente!"
          />
        </StepTitle>

        <p className={styles['center-step']}>
          Nos ajude a melhorar nossos serviços informando por qual motivo você não concluiu o seu
          pedido de orçamento:
        </p>

        {questions.map((question) => (
          <ButtonLabel key={question.value}>
            <label htmlFor={question.value}>
              <Input
                type="radio"
                name="answerContent"
                id={question.value}
                value={question.value}
                defaultChecked={selectedOption === question.value}
                data-testid="question"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setSelectedOption(event.target.value);
                }}
              />
              {question.label}
            </label>
          </ButtonLabel>
        ))}

        {selectedOption === '6' && (
          <Input
            value={optionTextOther}
            placeholder={'Escreva aqui o motivo...'}
            onChange={(e) => setOptionTextOther(e.target.value)}
            type="text"
            name="optionTextOtherContent"
            data-testid="optionTextOther"
          />
        )}
      </div>

      <Button
        type="button"
        variant={ButtonTypes.PRIMARY}
        onClick={handleSubmit}
        disabled={!selectedOption}
        data-testid="back"
      >
        Enviar
      </Button>
    </section>
  );
}
